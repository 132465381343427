<template>
    <div class="list">
        <div class="list-item" v-for="(item,index) in list" :key="index">
            <div class="infor">
                        <h3 class="font24">{{ item.companyName }}</h3>
                        <p class="PS clearfix" v-if="item.companyContact">
                            <span class="name">联系人： </span> <span class="text"> <span style="white-space:normal;">{{
        item.companyContact }}</span></span>
                        </p>
                        <p class="PS clearfix" v-if="item.companyCooperate">
                            <span class="name">业务合作：</span> <span class="text"> <span style="white-space:normal;">{{
        item.companyCooperate }}</span></span>
                        </p>
                        <!-- <p class="PS clearfix" v-if="item.companyCode">
                            <span class="name">邮编： </span> <span class="text"> {{ item.companyCode }}</span>
                        </p> -->
                        <p class="PS clearfix" v-if="item.companyEmail">
                            <span class="name">联系邮箱： </span> <span class="text"> {{ item.companyEmail }}</span>
                        </p>
                        <p class="PS clearfix" v-if="item.companyPhone">
                            <span class="name">联系电话： </span> <span class="text"> {{ item.companyPhone }}</span>
                        </p>
                        <!-- <p class="PS clearfix" v-if="item.companyFax">
                            <span class="name">传真 </span> <span class="text"> {{ item.companyFax }}</span>
                        </p> -->
                        <p class="PS clearfix" v-if="item.companyAddress">
                            <span class="name">地址： </span> <span class="text"> <span style="white-space:normal;">{{
                                    item.companyAddress }}</span></span>
                        </p>
                    </div>
        </div>
        <div class="list-item spe-list-item" v-for="(titem,tindex) in ((list.length%3)==0?0:(3-list.length%3))" :key="tindex+99"></div>
    </div>
    <!-- <el-carousel indicator-position="outside" :interval="5000" @change="changeCarousel" loop>
        <el-carousel-item v-for="(item, index) in list" :key="index">
            <div class="contact-minbox commonweb padtb">
                <div class="contact-dl clearfix">
                    <div class="thumb thumb-box">
                        <img :src="$BaseUrl + item.remark" />
                    </div>
                    <div class="infor">
                        <h3 class="font24">{{ item.companyName }}</h3>
                        <p class="PS clearfix" v-if="item.companyContact">
                            <span class="name">联系人： </span> <span class="text"> <span style="white-space:normal;">{{
        item.companyContact }}</span></span>
                        </p>
                        <p class="PS clearfix" v-if="item.companyCooperate">
                            <span class="name">业务合作：</span> <span class="text"> <span style="white-space:normal;">{{
        item.companyCooperate }}</span></span>
                        </p>
                        <p class="PS clearfix" v-if="item.companyCode">
                            <span class="name">邮编： </span> <span class="text"> {{ item.companyCode }}</span>
                        </p>
                        <p class="PS clearfix" v-if="item.companyEmail">
                            <span class="name">联系邮箱 </span> <span class="text"> {{ item.companyEmail }}</span>
                        </p>
                        <p class="PS clearfix" v-if="item.companyPhone">
                            <span class="name">联系电话 </span> <span class="text"> {{ item.companyPhone }}</span>
                        </p>
                        <p class="PS clearfix" v-if="item.companyFax">
                            <span class="name">传真 </span> <span class="text"> {{ item.companyFax }}</span>
                        </p>
                        <p class="PS clearfix" v-if="item.companyAddress">
                            <span class="name">地址： </span> <span class="text"> <span style="white-space:normal;">{{
                                    item.companyAddress }}</span></span>
                        </p>
                    </div>
                </div>
                <div class="map">
                    <div class="info-content">
                        <div :id="`mapbox${index}`" class="mapbox"></div>
                    </div>

                </div>
            </div>

        </el-carousel-item>
    </el-carousel> -->
</template>
<script>
import AMapLoader from "@amap/amap-jsapi-loader";
export default {
    props: {
        list: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    data() {
        return {
            map: null
        }
    },
    // destroyed(){
    //     if(this.map){
    //         this.map.destroy()
    //     }
    // },
    methods: {
        init() {
            this.$nextTick(() => {
                this.initMap(this.list[0], 0)
            })
        },
        initMap(item, itemIndex) {
            AMapLoader.load({
                key: "f9b3654d4844fffb1911b78b87a3ef23", // 申请好的Web端开发者Key，首次调用 load 时必填
                version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
                plugins: [], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
            })
                .then((AMap) => {
                    this.map = new AMap.Map(`mapbox${itemIndex}`, {
                        // 设置地图容器id
                        viewMode: "3D", // 是否为3D地图模式
                        // zoom: 4.2, // 初始化地图级别
                        center: [108.270899, 34.300017], // 初始化地图中心点位置
                        // mapStyle: 'amap://styles/darkblue'
                    });
                    var pos = []
                    if (item.coordinate) {
                        pos = item.coordinate.substring(1, item.coordinate.length - 1).split(',')
                    } else {
                        pos = "119.296389, 26.074268".split(', ')
                    }
                    let marker = new AMap.Marker({
                        position: new AMap.LngLat(pos[0], pos[1]),
                        title: item.companyName,
                        color: '#000',
                        label: {
                            content: item.companyName
                        },
                    })
                    // this.map.setCenter([pos[0], pos[1]])
                    this.map.add(marker)
                    this.map.setFitView()
                })
        },
        changeCarousel(index) {
            if (this.map) {
                this.map.destroy()
                this.$nextTick(() => {
                    this.initMap(this.list[index], index)
                })
            }
        }
    }
}
</script>
<style scoped>
.thumb-box {
    width: 558px;
    height: 346px;
}

.thumb-box img {
    max-width: 558px;
    max-height: 346px;
}

.contact-dl {
    min-height: 309px;
}

.mapbox {
    width: 100%;
    overflow: hidden;
    border: 1px solid #ccc;
}

@media (min-width:1000px) {
    /deep/.el-carousel__container {
        height: 700px;
    }

    .mapbox {
        height: 400px;
        margin-top: 20px;
    }
}

@media (max-width:1000px) {
    /deep/.el-carousel__container {
        height: 600px;
    }

    .mapbox {
        height: 300px;
        margin-top: 20px;
    }
}


.el-carousel ::v-deep .el-carousel__indicators {
    /* // 指示器 */
    left: unset;
    transform: unset;
    right: 2%;
}

.el-carousel ::v-deep .el-carousel__button {
    /* // 指示器按钮 */
    width: 10px;
    height: 10px;
    border: none;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.2);
}

.el-carousel ::v-deep .is-active .el-carousel__button {
    /* // 指示器激活按钮 */
    background: #3f8ec8;
}

.list{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.list-item{
    width: 33%;
    min-height: 220px;
    padding: 10px;
    box-sizing: border-box;
}
.list-item:hover{
    background-color: red;
    color:#fff!important;
}
.spe-list-item:hover{
    background-color: #fff;
}
</style>