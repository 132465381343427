<template>
  <header class="header">
    <div class="headerweb clearfix">
      <h3 class="logo col-md-3"
        style="padding:22px 0 0 20px;display: flex;align-items: center;justify-content: space-between;"> <a
          href="index.html"><img style="height: 60px;" src="@/assets/images/logo.jpeg" alt="logo" /></a>
        <span>股票代码：831308</span></h3>
      <div class="col-md-7">
        <!-- style="margin-left: 100px;" -->
        <div class="nav" id="navMenu">
          <ul>
            <li v-for="(item,index) in menuList" :key="index" :data-id="item.id" :class="{ 'active current': routeName == item.name }"><a :href="item.router=='/investorRelations'?'javascript:void(0)':item.router" @click="goPage">{{ item.title }}</a></li>
          </ul>
        </div>
        <div class="mask-bg" id="maskBg"></div>
        <span class="nav-toggle hidden-md wenmenu" id="navToggle"><i class="fa fa-bars"></i></span>
      </div>
      <div class="col-md-2">
        <div class="ss-box visible-md hidden-sm hidden-xs">
          <div class="col-xs-9 padding-0">
            <input type="text" class="s-input" placeholder="请输入关键字" v-model="keyword">
          </div>
          <div class="col-sm-3 col-xs-3">
            <button type="submit" class="s-btn col-xs-12" @click="searchByInput"><i class="fa fa-search" aria-hidden="true"></i></button>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header',
  props: {
    goSearchPage:{
      type:Boolean,
      default:true
    }
  },
  data() {
    return {
      menuList: [{
        name: 'home',
        title: '首页',
        router: '/',
        id:22
      }, {
        name: 'service',
        title: '我们的业务',
        router: '/service',
        id:46
      }, {
        name: 'product',
        title: '服务案例',
        router: '/product',
        id:272
      }
        , {
        name: 'News',
        title: '新闻中心',
        router: '/news',
        id:204
      }, {
        name: 'aboutUs',
        title: '关于华博',
        router: '/aboutUs',
        id:45
      }, {
        name: 'recruit',
        title: '招贤纳士',
        router: '/recruit',
        id:214
      }, {
        name: 'investorRelations',
        title: '投资者关系',
        router: '/investorRelations',
        id:1290
      }, {
        name: 'concactUs',
        title: '联系我们',
        router: '/concactUs',
        id:168
      }
      ],
      routeName: 'home',
      keyword:''
    }
  },
  created() {
    this.routeName = this.$route.name || 'home'
  },
  methods:{
    searchByInput(){
      if(this.goSearchPage){
        this.$router.push({path:'/query?keyword='+this.keyword})
      }else{
        this.$emit('search',this.keyword)
      }
    },
    goPage(){
            window.location.href='https://www.neeq.com.cn/products/neeq_listed_companies/related_announcement.html?companyCode=831308&typename=G'
            // window.open('https://www.neeq.com.cn/products/neeq_listed_companies/related_announcement.html?companyCode=831308&typename=G')
        }
  }
}
</script>

<style scoped>
.header{
  flex-shrink: 0;
}
.headerweb {
  max-width: 1920px;
}

.nav {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.nav>ul {
  width: 96%;
  display: flex;
  justify-content: flex-end;
}

.nav>ul>li {
  width: 12%;
  max-width: 100px;
}

.active-item .nav-link {
  color: #0354cc !important;
}

.fixed-header {
  position: fixed;
  top: 0;
  z-index: 999;
  background: #fff;
  /* padding: 15px; */
  padding: 0 !important;
}

.placeHbox {
  height: 90px;
}

.logo-left {
  height: 90px;
  min-width: 400px;
  max-width: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 200px;
}

.logo-left img {
  height: 60px;
}

.top-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 90px;
  flex: 1;
  min-width: 0;
  justify-content: space-between;
  background: #fff;
}

.code-box {
  padding-left: 40px;
  font-weight: bold;
}

.code-box span {
  font-weight: bold;
  color: red;
}

.navbar-light {
  display: flex;
}

.menu-list,
.navbar-nav {
  height: 90px;
}

.w3l-header-4 a.btn-style {
  height: 90px;
  line-height: 90px;
}

.menu-list .navbar-nav {
  flex-direction: row !important
}

.menu-list .navbar-nav .nav-item {
  display: flex;
  align-items: center;
}

.absolute-top {
  padding: 0 !important;
}
</style>
