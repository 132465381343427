<template>
    <div class="tab-optionsbox nav-lines">
        <div class="tab-options clearfix">
            <div class="commonweb">
                <a href="javascript:void(0)" v-for="(item, index) in list" :key="index"
                    :class="{ 'cur': index == current }" @click="navClick(item,index)">{{ item[labelKey] }}</a>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        list: {
            type: Array,
            default: () => {
                return []
            }
        },
        current: {
            type: Number,
            default: 0
        },
        labelKey: {
            type: String,
            default: 'label'
        }
    },
    data() {
        return {

        }
    },
    methods:{
        navClick(item,index){
            this.$emit('navClick',{item,index})
        }
    }
}
</script>
<style scoped>
.nav-lines .tab-options {
    text-align: left !important;
}

.nav-lines .tab-options a {
    margin: 0;
    margin-right: 25px;
    font-size: 14px;
    height: 50px;
}
</style>