<template>
  <div class="hgbw-page-area">
    <Header></Header>
    <div class="middle-area">
      <pageBanner :type="3"></pageBanner>
      <pageNavLine :leftList="navList" leftLabelKey="categoryName" :leftCurrent="curActive" :infoList="navInfoList" @navClick="clickNavItem"></pageNavLine>
      <!-- <cate-nav :leftList="towCateList" leftLabelKey="categoryName" :leftCurrent="twoCate" @navClick="twoCateClick" v-if="curActive==1"></cate-nav> -->
      <!-- <div class="common-clumb-min commonweb clearfix" v-if="curActive!=0">
        <div class="clumb-title">
          <h3 class="cn font22">{{ navList[curActive] ? navList[curActive].categoryName : '服务案例' }}</h3>
        </div>
      </div> -->
      <div class="product-list commonweb">
        <!-- 部分合作伙伴 -->
        <div v-if="curActive==0">
          <div class="commonweb">
            <div class="index-text center wow fadeInDown">
              <h2 class="cn font30">部分合作伙伴</h2>
              <!-- <h3 class="en font24">COMPANY CULTURE</h3> -->
            </div>
            <div class="inxpart-list">
              <div class="partner-wrapper homebanner">
                <div class="partner-list">
                  <vueSeamlessScroll :data="partnerList[0]" :class-option="defaultOption1">
                    <div class="lpartner-list">
                      <div v-for="(item,index) in partnerList[0]" :key="index" class="partner2-box">
                        <img :src="$BaseUrl + item.imgUrl" class="partner-img" />
                      </div>
                    </div>
                  </vueSeamlessScroll>
                </div>
                <div class="partner-list">
                  <vueSeamlessScroll :data="partnerList[1]" :class-option="defaultOption2">
                    <div class="lpartner-list">
                      <div v-for="(item,index) in partnerList[1]" :key="index" class="partner2-box">
                        <img :src="$BaseUrl + item.imgUrl" class="partner-img" />
                      </div>
                    </div>
                  </vueSeamlessScroll>
                </div>
                <div class="partner-list">
                  <vueSeamlessScroll :data="partnerList[2]" :class-option="defaultOption3">
                    <div class="lpartner-list">
                      <div v-for="(item,index) in partnerList[2]" :key="index" class="partner2-box">
                        <img :src="$BaseUrl + item.imgUrl" class="partner-img" />
                      </div>
                    </div>
                  </vueSeamlessScroll>
                </div>
              </div>

            </div>
          </div>
        </div>
        <!-- 典型案例 -->
        <div v-if="curActive==1">
          <div class="index-text center wow fadeInDown">
              <h2 class="cn font30 mt36">典型案例</h2>
              <!-- <h3 class="en font24">COMPANY CULTURE</h3> -->
            </div>
          <ul class="clearfix imgae-same-size">
            <li class="col-xs-6 col-sm-3" v-for="(item, index) in productList" :key="index">
              <!-- :href="`/productDetail?id=` + item.productsId" -->
              <a href="javascript:void(0)" :title="item.productName" @click="goDetail(item)">
                <div class="pro-img"> <img :src="$BaseUrl + item.productImage" :alt="item.productName" /> </div>
                <p class="pro-text">{{ item.productName }}</p>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <Footer></Footer>
    <backToTop></backToTop>
  </div>
</template>
<script>
import { getCategoryNav, getCategoryProduct,getProductAllList } from "@/api/product"
import vueSeamlessScroll from "vue-seamless-scroll";
import { getPartnerList } from "@/api/index.js";
import cateNav from "@/components/cateNav"
export default {
  components: {
    vueSeamlessScroll,cateNav
  },
  data() {
    return {
      navList: [],
      curActive: 0,
      productList: [],
      paramsId: null,
      navInfoList: [{
        name: '服务案例'
      }],
      partnerList: [[], [], []],
      defaultOption1: {
        step: 0.4, // 数值越大速度滚动越快
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 2, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动)direction => 0/1                                
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 100 // 单步运动停止的时间(默认值1000ms)
      },
      defaultOption2: {
        step: 0.5, // 数值越大速度滚动越快
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 2, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动)direction => 0/1                                
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 100 // 单步运动停止的时间(默认值1000ms)
      },
      defaultOption3: {
        step: 0.6, // 数值越大速度滚动越快
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 2, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动)direction => 0/1                                
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 100 // 单步运动停止的时间(默认值1000ms)
      },
      towCateList:[],
      twoCate:0
    }
  },
  mounted() {
    if (this.$route.query.id) {
      this.paramsId = this.$route.query.id
    }
    getPartnerList().then(res => {
      this.partnerList = (res.rows || []).reduce((res, curV, curI) => {
        let idx = curI % 3
        res[idx].push(curV)
        return res
      }, [[], [], []])
    })
    // if(this.$route.query.twoId){
    //   this.goTwoCate()
    // }else{
      this.getNav() 
    // }
  },
  methods: {
    async getNav() {
      this.navList = [{
        categoryName: '部分合作伙伴',
        value: 0
      }, {
        categoryName: '典型案例',
        value: 1
      }]
      this.curActive = 0
      if(this.paramsId!=null){
        let idx = this.navList.findIndex(t => { return t.value == this.paramsId })
        if(idx!=-1&&idx!=0){
          this.curActive=idx
        }
      }
      // this.towCateList=await getCategoryNav({sortType:2})
      this.clickNavItem({ item: this.navList[this.curActive], index: this.curActive })
      // getCategoryNav({sortType:2}).then(res => {
      //   this.towCateList = res
      //   // if (this.paramsId != null) {
      //   //   let idx = this.navList.findIndex(t => { return t.categoryId == this.paramsId })
      //   //   if (idx != -1 && idx != 0) {
      //   //     this.curActive = idx
      //   //   }
      //   // }
      //   // this.clickNavItem({ item: this.navList[this.curActive], index: this.curActive })
      // })
    },
    clickNavItem({ item, index }) {
      this.curActive = index
      if (item.value == 0) {

      } else {
        // this.twoCate=0
        // this.twoCateClick({item:this.towCateList[this.twoCate],index:this.twoCate})
        getProductAllList().then(res=>{
          this.productList=res.rows
        })
        // getCategoryProduct(item.categoryId).then(res => {
        //   this.productList = []
        //   if (Object.keys(res).length != 0) {
        //     let arr = Object.keys(res)
        //     arr.forEach(t => {
        //       // let obj = {
        //       //   categoryName: t,
        //       //   list: res[t]
        //       // }
        //       this.productList = res[t]
        //       // this.productList.push(obj)
        //     })
        //   }
        // })
      }
    },
    goDetail(item) {
      // this.$router.push({path:'http://www.baidu.com'})
      // console.log(Number(item.isLink) == 1)

      if (Number(item.isLink) == 1) {
        // // { 是外链
        // if (pattern.test(item.linkUrl)) {
        window.open(item.linkUrl, '_self')
        // } else {
        // alert('外链配置错误！')
        // }
      } else {
        this.$router.push({ path: '/productDetail?id=' + item.productsId })
      }
    },
    twoCateClick({ item, index }){
      this.twoCate=index
              getCategoryProduct(item.categoryId).then(res => {
          this.productList = []
          if (Object.keys(res).length != 0) {
            let arr = Object.keys(res)
            arr.forEach(t => {
              // let obj = {
              //   categoryName: t,
              //   list: res[t]
              // }
              this.productList = res[t]
              // this.productList.push(obj)
            })
          }
        })
    },
    // 指定到第二级分类
    async goTwoCate(){
      this.navList = [{
        categoryName: '部分合作伙伴',
        value: 0
      }, {
        categoryName: '典型案例',
        value: 1
      }]
      this.towCateList=await getCategoryNav({sortType:2})
      this.curActive = 1
      if(this.$route.query.twoId){
        let idx=this.towCateList.findIndex(v=>{return v.categoryId==this.$route.query.twoId})
        this.twoCate=idx==-1?0:idx 
      }else{
        this.twoCate=0
      }
      console.log(this.twoCate)
      this.twoCateClick({item:this.towCateList[this.twoCate],index:this.twoCate})
    }
  }
}
</script>
<style scoped>
.pro-img {
  width: 277px;
  height: 139px;
  overflow: hidden;
  /* display: flex;
  align-items: flex-end;
  justify-content: center; */
}

.pro-img img {
  width: 277px;
  min-height: 139px;
  object-fit: contain;
  /* object-fit: cover; */
}
.partner-list {
  display: flex;
  margin-bottom: 10px;
}
.lpartner-list {
  display: flex;
  width: 100%;
}
.partner2-box {
  margin: 0 20px;
  background-color: #fff;
  min-width: 300px;
  display: inline-block;
}
.partner-img {
  width: 300px;
  height: 80px;
  object-fit: contain;
}
.mt36{
  margin-bottom: 36px;
}
</style>