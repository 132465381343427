export const language = { 
    freeShipping: 'Free Shipping for all Order of $99',
    home:'HOME',
    shop:'SHOP',
    contact:'CONTACT',
    blog:'BLOG',
    departments:'All departments',
    categories:'All Categories',
    need:'What do yo u need?',
    supportTime:'support 24/7 time',
    searchBtn:'SEARCH',
    featured:'Featured Product',
    latest:'Latest Products',
    top:'Top Rated Products',
    review:'Review Products',
    address:'Address',
    phone:'Phone',
    email:'Email',
    useful:'Useful Links',
    join:'Join Our Newsletter Now',
    getEmail:'Get E-mail updates about our latest shop and special offers.',
    subscribe:'Subscribe',
    enterEmail:'Enter your mail',
    saleOff:'Sale Off',
    price:'Price',
    sortBy:'Sort By',
    asc:'asc',
    desc:'desc',
    productsFound:'Products found',
    detail:'Details',
    availability:'Availability',
    stock:'In Stock',
    shipping:'Shipping',
    shippingInfo:'01 day shipping. Free pickup today',
    weight:'Weight',
    description:'Description',
    related:'Related Product'
  }