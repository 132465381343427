import Vue from 'vue'
import Router from 'vue-router'
import Home from '../views/index/index'
import news from '../views/news/index'
import newsDetail from '../views/news/detail'
import product from '../views/product/index'
import productDetail from '../views/product/detail'
import aboutUs  from '../views/aboutUs/index'
import concactUs from "../views/concactUs/index"
import investorRelations from "../views/investorRelations/index"
import service from "../views/service/index"
import recruit from "../views/recruit/index"
import query from "../views/query/index"
Vue.use(Router)
//路由
export const routes = [
  {
    path: '/',
    component:Home,
    name:'home'
  },{
    path: '/news',
    component:news,
    name:'News'
  },{
    path: '/newsDetail',
    component:newsDetail,
    name:'News'    
  },{
    path: '/product',
    component:product,
    name:'product'
  },{
    path: '/productDetail',
    component:productDetail,
    name:'product'
  },{
    path: '/aboutUs',
    component:aboutUs,
    name:'aboutUs'
  },{
    path:'/concactUs',
    component:concactUs,
    name:'concactUs'
  },{
    path:'/investorRelations',
    component:investorRelations,
    name:'investorRelations'
  },{
    path:'/service',
    component:service,
    name:'service'
  },{
    path:'/recruit',
    component:recruit,
    name:'recruit'
  },{
    path:'/query',
    component:query,
    name:'query'    
  }
]

export default new Router({
  mode: 'history', // 去掉url中的#
  scrollBehavior: () => ({ y: 0 }),
  routes
})
