<template>
  <div id="app">
     <router-view />
  </div>
</template>

<script>

export default {
  name: 'App',

}
</script>

<style>
#app{
  min-width:1200px;
  min-height: 100vh;
}
.overHidden2{
  overflow:hidden; 
text-overflow:ellipsis;
display:-webkit-box; 
-webkit-box-orient:vertical;
-webkit-line-clamp:2; 
}
.hgbw-page-area{
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
}
.middle-area{
  /* min-height: 100vh; */
  flex: 1;
  min-height: 0;
}
.pt0{
  padding-top: 0!important;
}
</style>
