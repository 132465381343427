<template>
  <div id="home" class="hgbw-page-area">
    <Header></Header>
    <div class="middle-area">
      <pageBanner :type="0"></pageBanner>
      <pageNavLine :leftNavShow="false" :infoList="navInfoList"></pageNavLine>
      <div class="common-clumb-min commonweb clearfix">
        <div class="clumb-title">
          <h3 class="cn font22">新闻中心</h3>
          <!-- <h2 class="en font24">NEWS CENTER</h2> -->
        </div>
      </div>

      <div class="newscenter-list commonweb phonepadding">
        <ul class="clearfix imgae-same-size">
          <li class="col-sm-4" v-for="(item, index) in list" :key="index">
            <a :href="'/newsDetail?id=' + item.newsId" :title="item.title">
              <div class="news-img"> <img :src="$BaseUrl + item.image" :alt="item.title" /></div>
              <div class="news-text">
                <h3 class="name">{{ item.title }}</h3>
                <p class="desc">
                  {{ item.desc }}
                </p>
                <span class="date">{{ item.publishTime }}</span>
              </div>
            </a>
          </li>
        </ul>
      </div>
      <pagination :current="currentPage" :pages="pages" @pageChange="clickPage"></pagination>
    </div>
    <Footer></Footer>
    <backToTop></backToTop>
  </div>
</template>
<script>
import { getNewsList } from "@/api/new.js";
import pagination from "@/components/pagination"
export default {
  components: { pagination },
  data() {
    return {
      list: [],
      currentPage: 1,
      pages: 1,
      navInfoList: [{
        name: '新闻中心'
      }]
    }
  },
  mounted() {
    this.getNewsList()
  },
  methods: {
    getNewsList() {
      let params = {
        pageNum: this.currentPage,
        pageSize: 9
      }
      getNewsList(params).then(res => {
        this.list = res.rows.map(v => {
          v.content = v.content.replace(/<img.*?>/g, "")
          v.desc = v.content.replace(/<\/?.+?\/?>/g, ' ')
          return v
        })
        this.pages = Math.ceil(res.total / 9)
      })
    },
    clickPage(index) {
      this.currentPage = index
      this.getNewsList()
    },
    goDetail(item) {
      this.$router.push({ path: '/newsDetail?id=' + item.newsId })
    }
  }
}
</script>
<style scoped>
.imgae-same-size {
  display: flex;
  flex-wrap: wrap;
}

.news-img {
  width: 370px;
  height: 260px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.news-img img {
  width: 370px;
  height: 260px;
  object-fit: cover;
}
</style>