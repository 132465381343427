import request from '@/utils/request'

export function getNewsList(data) {
    return request({
        url: '/hbgwOnstage/nav/news',
        method: 'get',
        params:data
    })
}
export function getNewsDetail(id) {
    return request({
        url: '/hbgwOnstage/nav/news/'+id,
        method: 'get'
    })
}