<template>
  <div class="full-inside-subnav">
    <div class="commonweb clearfix">
      <div class="inside-subnav">
        <div class="subnav cate-subnav clearfix" id="subNav">
          <ul>
            <li v-for="(item,index) in leftList" :key="index" @click="navClick(item,index)" :class="index==leftCurrent?'active':''">
              <a>{{ item[leftLabelKey] }}</a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    leftList: {
      type: Array,
      default: () => {
        return []
      }
    },
    leftLabelKey: {
      type: String,
      default: 'label'
    },
    leftCurrent: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {

    }
  },
  methods: {
    navClick(item, index) {
      this.$emit('navClick', { item, index })
    }
  }
}
</script>
<style scoped>
.inside-subnav .cate-subnav li.active a:after{content: "";display: none;}
.full-inside-subnav .subnav li:hover a:after{
    display: none!important;
}
.full-inside-subnav{
    border-bottom: 0;
}
</style>