<template>
    <footer class="footer">
        <div class="footer-top commonweb clearfix">
            <div class="col-md-9 footnav padding-right-0 visible-md hidden-sm hidden-xs footer-btn">
                <ul>
                    <li data-id="22"><a href="/">网站首页</a></li>
                    <li data-id="46" class="liFlex2"><a href="/service">我们的业务</a>
                        <ul class="btn-list">
                            <li data-id="220" v-for="(item, index) in serverList" :key="index"><a
                                    :href="`/service?name=${item.title}`" class="overHidden">{{ item.title }}</a></li>
                        </ul>
                    </li>
                    <li data-id="272" class="liFlex1"><a href="/product">服务案例</a>
                        <ul>
                            <li><a :href="`/product?id=0`">部分合作伙伴</a></li>
                            <li><a :href="`/product?id=1`">典型案例</a></li>
                        </ul>
                    </li>
                    <li data-id="204" class="liFlex1"><a href="/news">新闻中心</a>
                    </li>
                    <li data-id="45" class="liFlex1"><a href="/aboutUs">关于华博</a>
                        <ul>
                            <li v-for="(item, index) in aboutList" :key="index"><a
                                    :href="`/aboutUs?id=${item.aboutId}`">{{ item.title }}</a></li>

                        </ul>
                    </li>
                    <li data-id="214" class="liFlex1"><a href="/recruit">招贤纳士</a>
                    </li>
                    <li data-id="1290" class="liFlex1"><a href="javascript:void(0)" @click="goPage">投资者关系</a>
                        <!-- <ul>
                            <li v-for="(item, index) in investorRelationList" :key="index"><a
                                    :href="`/investorRelations?id=${item.categoryOne}`">{{ item.title }}</a></li>
                        </ul> -->
                    </li>
                    <li data-id="168" class="liFlex1"><a href="/concactUs">联系我们</a>
                        <ul>
                            <li v-for="(item, index) in concactList" :key="index"><a
                                    :href="`/concactUs?title=${item}`">{{ item }}</a></li>
                        </ul>
                    </li>
                </ul>
            </div>
            <div class="col-md-3 right padding-left-0">
                <div class="ewm-area">
                    <img :src="$BaseUrl + item.imgUrl" v-for="(item, index) in (homeInfo.webHbgwImgVo)" :key="index">
                </div>
                <p class="telnum"> Copyright &copy; 2002-2014 华博科技有限公司</p>
                <p> 网站备案号：<a href="https://beian.miit.gov.cn" target="_blank">闽ICP备08103886号-2</a> </p>
            </div>
        </div>

        <!--友情链接-->
        <div class="friendlink">
            <div class="commonweb">
                <!-- <p> <span>友情链接：</span> <a href='http://www.pageadmin.net/' target='_blank'>帝天科技</a> <a
                        href='http://www.pageadmin.net/' target='_blank'>百度</a> <a href='http://www.pageadmin.net/'
                        target='_blank'>华博</a> <a href='http://www.pageadmin.net/' target='_blank'>新蓝网</a> </p> -->
            </div>
        </div>
    </footer>
</template>

<script>
import { getServiceList } from '@/api/service'
import { getAboutUs } from "@/api/aboutUs"
import { getDicts } from "@/api/common"
import { getNavList as getInvestorRelation } from "@/api/investorRelations"
import { homeContactUs } from "@/api/index.js";
export default {
    name: 'Footer',
    data() {
        return {
            serverList: [],
            aboutList: [],
            aboutObject: {},
            concactList: ['联系我们', '在线留言'],
            investorRelationObject: {},
            // investorRelationList: [],
            homeInfo: {
                webHbgwImgVo: []
            }
        }
    },
    async mounted() {
        let res = await getDicts('about_us_type')
        res.forEach(v => {
            this.$set(this.aboutObject, Number(v.dictValue), v.dictLabel)
        })
        // let res2 = await getDicts('investor_relations')
        // res2.forEach(v => {
        //     this.$set(this.investorRelationObject, v.dictValue, v.dictLabel)
        // })
        this.getNavList()
    },
    methods: {
        getNavList() {
            getServiceList().then(res => {
                this.serverList = Object.keys(res).map((v, index) => {
                    return {
                        title: v
                    }
                })
            })
            // 关于华博
            getAboutUs().then(res => {
                let temp=[]
                 res.forEach(v => {
                    v.title = this.aboutObject[Number(v.aboutCategory)]
                    if(v.title!='企业文化'&&v.aboutId!=2){
                        temp.push(v)
                    }
                    // return v
                })
                this.aboutList = temp
            })
            // 投资关系
            // getInvestorRelation().then(res => {
            //     this.investorRelationList = res.map(v => {
            //         v.title = this.investorRelationObject[v.categoryOne]
            //         return v
            //     })
            // })
            // 华博信息
            homeContactUs().then(res => {
                this.homeInfo = res
            })
        },
        goPage(){
            window.location.href='https://www.neeq.com.cn/products/neeq_listed_companies/related_announcement.html?companyCode=831308&typename=G'
            // window.open('https://www.neeq.com.cn/products/neeq_listed_companies/related_announcement.html?companyCode=831308&typename=G')
        }
    }
}
</script>

<style scoped>
/* .container{
  position: fixed;
  bottom: 0;
}
.placeHbox{
  height: 75px;
} */
.footer{
    flex-shrink: 0;
}
.footer-btn>ul{
    display: flex;
}
.footer-btn>ul>li {
    flex-shrink: 0;
    min-width: 0;
}
.liFlex1{
    flex: 1!important;
}
.liFlex2{
    flex: 2!important;
}
.footer-btn>ul>li:first-of-type{
    width: 0!important;
    flex: 0!important;
}
.btn-list {
    width: 100%;
}

.btn-list li {
    width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.ewm-area {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}

.ewm-area img {
    width: 100px;
    height: 100px;
}
</style>
