<template>
    <div class="hgbw-page-area">
        <Header :goSearchPage="false" @search="getkeyword"></Header>
        <div class="middle-area">
            <pageBanner :type="4"></pageBanner>
            <pageNavLine :leftList="navList" leftLabelKey="label" :leftCurrent="curActive" :infoList="navInfoList"
                @navClick="clickNavItem"></pageNavLine>
            <div class="common-clumb-min commonweb clearfix">
                <div class="clumb-title">
                    <h3 class="cn font22">关键词:{{ keyword }}</h3>
                    <!-- <h2 class="en font24">KEYWORD SEARCH</h2> -->
                </div>
            </div>
            <!-- 新闻 -->
            <template v-if="navList[curActive] && navList[curActive].value == 'news'">
                <div class="newscenter-list commonweb phonepadding">
                    <ul class="clearfix imgae-same-size">
                        <li class="col-sm-4" v-for="(item, index) in list" :key="index">
                            <a :href="'/newsDetail?id=' + item.newsId" :title="item.title">
                                <div class="news-img"> <img :src="$BaseUrl + item.image" :alt="item.title" /></div>
                                <div class="news-text">
                                    <h3 class="name">{{ item.title }}</h3>
                                    <p class="desc">
                                        {{ item.desc }}
                                    </p>
                                    <span class="date">{{ item.publishTime }}</span>
                                </div>
                            </a>
                        </li>
                    </ul>
                </div>
            </template>
            <!-- 投资者关系 -->
            <template v-if="navList[curActive] && navList[curActive].value == 'invest'">
                <div class="Download-list commonweb phonepadding">
                    <ul>
                        <li v-for="(item, index) in list" :key="index"> <span class="down-text">{{ item.title }}</span>
                            <a href="javascript:void(0)" :title="`${item.title}下载`" class="down-btn"
                                @click="openFile(item)"></a>
                        </li>
                    </ul>
                </div>
            </template>
            <!-- 服务案例 -->
            <template v-if="navList[curActive] && navList[curActive].value == 'products'">
                <div class="product-list commonweb">
                    <ul class="clearfix imgae-same-size">
                        <li class="col-xs-6 col-sm-3" v-for="(item, index) in list" :key="index">
                            <a :href="`/productDetail?id=` + item.productsId" :title="item.productName">
                                <div class="pro-img"> <img :src="$BaseUrl + item.productImage"
                                        :alt="item.productName" />
                                </div>
                                <p class="pro-text">{{ item.productName }}</p>
                            </a>
                        </li>
                    </ul>
                </div>
            </template>
            <!-- 招纳贤士 -->
            <template v-if="navList[curActive] && navList[curActive].value == 'recruitment'">
                <div class="recruit-list commonweb">
                    <ul class="margin-top-40">
                        <li class="padding-bottom-20" v-for="(item, index) in list" :key="index">
                            <div class="card1 font-size-16 font-weight-700">{{ item.jobTitle }}</div>
                            <p class="card2 margin-top-10" v-if="item.location">工作地点:{{ item.location }}</p>
                            <p class="card2">岗位描述:</p>
                            <p class="card2 para-textarea">{{ item.jobDescription }}</p>
                            <p class="card2">岗位要求:</p>
                            <p class="card2 para-textarea">{{ item.educationRequirements }}</p>
                        </li>
                    </ul>
                    <div class="btn-line">
                        <div class="recruit-btn" @click="sendMail">
                            投递简历
                        </div>
                    </div>
                </div>
            </template>
            <!-- 联系我们 -->
            <template v-if="navList[curActive] && navList[curActive].value == 'contactUs'">
                <div class="Pledge-min commonweb padtb">
                    <div class="Pledetext">
                        <company-swiper :list="list" ref="companySwiper"></company-swiper>
                    </div>
                </div>
            </template>
        </div>
        <backToTop></backToTop>
        <Footer></Footer>
    </div>
</template>
<script>
import { homeQuery } from "@/api/index"
import companySwiper from "@/components/companySwiper"
export default {
    components: {
        companySwiper
    },
    data() {
        return {
            allNavList: [{
                label: '联系我们',
                value: 'contactUs'
            }, {
                label: '投资者关系',
                value: 'invest'
            }, {
                label: '新闻中心',
                value: 'news'
            }, {
                label: '服务案例',
                value: 'products'
            }, {
                label: '招纳贤士',
                value: 'recruitment'
            }],
            navList: [],
            curActive: 0,
            navInfoList: [{
                name: '搜索中心'
            }],
            searchObject: {},
            list: [],
            keyword: ''
        }
    },
    mounted() {
        this.getSearchList(this.$route.query.keyword)
    },
    methods: {
        getSearchList(key) {
            this.navList = []
            let keyword = key
            this.keyword = key
            if (keyword && keyword != '') {
                homeQuery({ title: keyword }).then(res => {
                    this.searchObject = { ...res }
                    let resList = Object.keys(res)
                    resList.forEach(t => {
                        if (res[t].length != 0 && res[t][0].length && res[t][0].length != 0) {
                            let obj = this.allNavList.find(v => v.value == t)
                            if (obj) {
                                this.navList.push(obj)
                            }
                        }
                    })
                    if (this.navList && this.navList.length != 0) {
                        this.clickNavItem({ item: this.navList[0], index: 0 })
                    }
                })
            }
        },
        getkeyword(key) {
            this.getSearchList(key)
        },
        clickNavItem({ item, index }) {
            this.curActive = index
            let list = [...this.searchObject[item.value]]
            this.list = list.length == 0 ? [] : list[0]
            if (item.value == 'contactUs') {
                this.$nextTick(() => {
                    this.$refs.companySwiper.init()
                })
            }
        },
        openFile(item) {
            window.open(this.$BaseUrl + item.file, ' blank');
        },
        sendMail() {
            self.location.href = 'mailto:hr@fjhb.cn'
        }
    }
}
</script>
<style scoped>
.query-line {
    cursor: pointer;
}

/* 新闻 */
.news-img {
    width: 370px;
    height: 260px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.news-img img {
    max-width: 370px;
    max-height: 260px;
}

/* 服务案例 */
.pro-img {
    width: 277px;
    height: 139px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pro-img img {
    max-width: 277px;
    max-height: 139px;
}

/* 招纳贤士 */
.card2 {
    height: auto;
    white-space: pre-wrap;
}

.btn-line {
    display: flex;
    justify-content: flex-end;
}

.recruit-btn {
    text-transform: uppercase;
    font-family: Rubik;
    font-size: 16px;
    background: #d82019;
    color: #fff;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    border-radius: 20px;
    cursor: pointer;
    user-select: none;
}
</style>