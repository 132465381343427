import Vue from 'vue'
import VueI18n from 'vue-i18n'
import App from './App.vue'
import router from "./router"
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';
import pageBanner from "./components/pageBanner"
import pageNavLine from "./components/pageNavLine"
import backToTop from "./components/backToTop"
import Element from 'element-ui'
Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: 'zh-CN',    // 语言标识
  messages: {
    'zh-CN': require('@/common/lang/zh'),   // 中文语言包
    'en-US': require('@/common/lang/en')    // 英文语言包
  }
})
Vue.component("Header", Header);
Vue.component("Footer", Footer);
Vue.component("pageNavLine", pageNavLine);
Vue.component("pageBanner", pageBanner);
Vue.component("backToTop", backToTop);
Vue.use(Element)
Vue.config.productionTip = false
Vue.prototype.$BaseUrl='http://www.fjhb.cn/api'
// Vue.prototype.$BaseUrl='http://114.67.202.45:29092'
new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
