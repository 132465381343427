<template>
  <div class="hgbw-page-area">
    <Header></Header>
    <div class="middle-area">
      <pageBanner :type="1"></pageBanner>
      <pageNavLine :leftList="navList" :leftCurrent="curActive" :infoList="navInfoList" @navClick="clickNavItem">
      </pageNavLine>
      <div class="common-clumb-min commonweb">
        <!-- clearfix -->
        <div class="clumb-title">
          <h3 class="cn font22">人才招聘<span class="recuit-tip">邮箱:hr@fjhb.cn</span>
          </h3>
          <!-- <h2 class="en font24">RECRUITMENT</h2> -->
        </div>
      </div>
      <div class="recruit-list commonweb">
        <ul class="margin-top-40">
          <li class="padding-bottom-20" v-for="(item, index) in list" :key="index">
            <div class="card1 font-size-16 font-weight-700">{{ item.jobTitle }}</div>
            <p class="card2-new margin-top-10" v-if="item.location"><label class="bold">工作地点:</label>{{ item.location }}</p>
            <p class="card2-new bold">岗位职责:</p>
            <p class="card2-new para-textarea">{{ item.jobDescription }}</p>
            <p class="card2-new bold">岗位要求:</p>
            <p class="card2-new para-textarea">{{ item.educationRequirements }}</p>
          </li>
        </ul>
        <div class="btn-line">
          <div class="recruit-btn" @click="sendMail">
            投递简历
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
    <backToTop></backToTop>
  </div>
</template>
<script>
import { getRecruitmentList } from "@/api/recruit"
import { getDicts } from "@/api/common"
export default {
  data() {
    return {
      navList: [],
      curActive: 0,
      typeList: [],
      list: [],
      infoObject: {},
      navInfoList: [{
        name: '招贤纳士',
      }]
    }
  },
  async mounted() {
    let res = await getDicts('job_type')
    this.typeList = res.map(t => {
      let obj = {
        value: Number(t.dictValue),
        label: t.dictLabel
      }
      return obj
    })
    this.getList()
  },
  methods: {
    getList() {
      this.navList = []
      getRecruitmentList().then(res => {
        this.infoObject = res
        let arr = Object.keys(res)
        arr.forEach(v => {
          let obj = this.typeList.find(t => t.value == v)
          if (obj) {
            this.navList.push(obj)
          }
        })
        if (arr.length != 0) {
          if (this.$route.query.type != undefined) {
            let idx = arr.findIndex(t => { return t == this.$route.query.type })
            this.curActive = idx == -1 ? 0 : idx
          }
          this.clickNavItem({ item: this.navList[this.curActive], index: this.curActive })
        }
      })
    },
    clickNavItem({ item, index }) {
      this.curActive = index
      this.list = this.infoObject[item.value]
    },
    sendMail() {
      self.location.href = 'mailto:hr@fjhb.cn'
    }
  }
}
</script>
<style scoped>
.card2-new {
  height: auto;
  white-space: pre-wrap;
}

.common-clumb-min {
  display: flex;
  justify-content: space-between;
}

.recuit-info-row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  float: right;
}

.recuit-info-row p {
  color: #34364b;
  font-size: 18px;
}

.recuit-tip {
  font-size: 14px;
  margin-left: 15px;
}

.btn-line {
  display: flex;
  justify-content: flex-end;
}

.recruit-btn {
  text-transform: uppercase;
  font-family: Rubik;
  font-size: 16px;
  background: #d82019;
  color: #fff;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  border-radius: 20px;
  cursor: pointer;
  user-select: none;
}
.bold{
  font-weight: bold;
}
</style>