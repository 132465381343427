import request from '@/utils/request'

export function getNavList() {
    return request({
        url: '/hbgwOnstage/nav/contactUs',
        method: 'get'
    })
}
// 分公司
export function getContactInfo() {
    return request({
        url: '/hbgwOnstage/nav/contactUs/info',
        method: 'get',
    })
}
// 在线留言
export function addConcact(data) {
    return request({
        url: '/hbgwOnstage/nav/contactUs/add',
        method: 'post',
        data:data
    })
}
// 帮助中心
export function getHelpCenter() {
    return request({
        url: '/hbgwOnstage/nav/contactUs/help',
        method: 'get',
    })
}