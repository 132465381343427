<template>
        <div class="full-inside-subnav">
      <div class="commonweb clearfix">
        <div class="inside-subnav" v-if="leftNavShow">
          <div class="subnav clearfix" id="subNav">
            <ul>
                <li v-for="(item,index) in leftList" :key="index" @click="navClick(item,index)" :class="index==leftCurrent?'active':''">
                    <a>{{ item[leftLabelKey] }}</a></li>  
              <!-- <li data-id="206"><a href="../xydt/index.html">公司新闻</a></li>
              <li data-id="205"><a href="../xyxws/index.html">行业新闻</a></li>
              <li data-id="1282"><a href="../mtbds/index.html">媒体报道</a></li>
              <li data-id="1289"><a href="../kjxw/index.html">科技新闻</a></li> -->
            </ul>
          </div>
        </div>
        <div class="crumb" v-if="rightNavShow">
          <div class="inner">
            <a href="/" class="home"><i class="fa fa-home text-red" aria-hidden="true"></i></a> <a
              href='/'>首页</a>
            <a :href="item.url?item.url:'javascript:void(0)'" v-for="(item,index) in infoList" :key="index"> > {{ item.name }}</a>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
export default {
    props:{
        leftNavShow:{
            type:Boolean,
            default:true
        },
        rightNavShow:{
            type:Boolean,
            default:true
        },
        infoList:{
            type:Array,
            default:()=>{
                return []
            }
        },
        leftList:{
            type:Array,
            default:()=>{
                return []
            }
        },
        leftLabelKey:{
            type:String,
            default:'label'
        },
        leftCurrent:{
          type:Number,
          default:0
        }
    },
    data(){
        return{

        }
    },
    methods:{
        navClick(item,index){
            this.$emit('navClick',{item,index})
        }
    }
}
</script>