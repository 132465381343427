import Cookies from 'js-cookie'

const TokenKey = 'X-User-Token'

export function getToken() {
  return Cookies.get(TokenKey) || 'd4d6f0f2882e4baba950b327ab6e7fa4'
}

export function setToken(token) {
  return Cookies.set(TokenKey, token)
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}
