import request from '@/utils/request'

export function getCategoryNav(params) {
    return request({
        url: '/hbgwOnstage/nav/category',
        method: 'get',
        params:params
    })
}
export function getCategoryProduct(id) {
    return request({
        url: '/hbgwOnstage/nav/category/'+id,
        method: 'get'
    })
}
export function getProductDetail(id) {
    return request({
        url: '/hbgwOnstage/homePage/products/'+id,
        method: 'get'
    })
}

export function getProductAllList() {
    return request({
        url: '/hbgwOnstage/homePage/productsAllList',
        method: 'get'
    })
}