<template>
  <div class="concact-area">
    <div class="left">
      <div class="mapBox" id="gdMap"></div>
    </div>
    <div class="right">
      <p class="para title" v-if="homeCompanyInfo.CustomerPhone">省内热线：{{homeCompanyInfo.CustomerPhone}}</p>
      <p class="para title" v-if="homeCompanyInfo.AbroadPhone">全国热线：{{homeCompanyInfo.AbroadPhone}}</p>
      <p class="para title" v-if="homeCompanyInfo.Email">客服邮箱：{{homeCompanyInfo.Email}}</p>
      <template v-if="infoList[curIndex]">
        <div class="info-box">
        <p class="name">{{infoList[curIndex].companyName}}</p>
        <p class="para" v-if="infoList[curIndex].companyAddress">地址:{{infoList[curIndex].companyAddress}}</p>
        <p class="para" v-if="infoList[curIndex].companyContact" >联系人: {{infoList[curIndex].companyContact}}</p>
        <p class="para">联系邮箱:{{infoList[curIndex].companyEmail}}</p>
        <p class="para">联系电话:{{infoList[curIndex].companyPhone}}</p>
        <p class="para" v-if="infoList[curIndex].fix" >传真:{{infoList[curIndex].companyFax}}</p>
        <!-- <p class="para spe-para" v-if="curIndex!=0">11</p> -->
        </div>
      </template>
      <div class="spe-line">
        <div class="spe-item" v-for="(item,index) in (homeCompanyInfo.webHbgwImgVo)" :key="index">
          <img :src="$BaseUrl+item.imgUrl"/>
          <p>{{item.description}} </p>
        </div>
        <!-- <div class="spe-item">
          <img src="@/assets/images/homImg2.png"/>
          <p>新华会计网官方微信 </p>
        </div> -->
              <a class="btn btn-theme2 mt-md-5 mt-4" href="/concactUs">联系我们</a>
      </div>
    </div>
  </div>
</template>
<script>
import AMapLoader from "@amap/amap-jsapi-loader";
import { homeContactUs } from "@/api/index.js";
export default {
  data() {
    return {
      // ad7219c61c47046073eb06a65f7c8df5
      map: null,
      markList: [],
      list: [{
        pos: [119.296389, 26.074268],
        title: '福建华博教育科技股份有限公司',
        iconLabel:'福州(总公司)'
      }, {
        pos: [103.834249, 36.06081],
        title: '福建华博教育科技股份有限公司甘肃分公司',
        iconLabel:'甘肃(分公司)'
      }, {
        pos: [118.796623, 32.059352],
        title: '福建华博教育科技股份有限公司江苏分公司',
        iconLabel:'江苏(分公司)'
      }, {
        pos: [113.625351, 34.746303],
        title: '福建华博教育科技股份有限公司河南分公司',
        iconLabel:'河南(分公司)'
      }, {
        pos: [104.066143, 30.573095],
        title: '福建华博教育科技股份有限公司四川办事处',
        iconLabel:'四川(分公司)'
      }, {
        pos: [116.407387, 39.904179],
        title: '北京南瑞利华教育科技有限公司',
        iconLabel:'北京(分公司)'
      }],
      infoList: [{
        name: '福建华博教育科技股份有限公司',
        address: '福州市鼓楼区工业路611号海峡技术转移中心大楼13层',
        email: 'hbkj@fjhb.cn',
        phone: '0591-83709511',
        fix: '0591-83791711'
      }, {
        name: '福建华博教育科技股份有限公司甘肃分公司',
        email: 'hbkj@fjhb.cn',
        phone: '13313983679',
      }, {
        name: '福建华博教育科技股份有限公司江苏分公司',
        email: 'hbkj@fjhb.cn',
        phone: '13313983679',
      }, {
        name: '福建华博教育科技股份有限公司河南分公司',
        email: 'hbkj@fjhb.cn',
        phone: '13313983679',
      }, {
        name: '福建华博教育科技股份有限公司四川办事处',
        email: 'hbkj@fjhb.cn',
        phone: '13313983679',
      }, {
        name: '北京南瑞利华教育科技有限公司',
        email: 'hbkj@fjhb.cn',
        phone: '13313983679',
      }],
      curIndex: 0,
      homeCompanyInfo:{}
      
    }
  },
  mounted() {
    homeContactUs().then(res=>{
      this.homeCompanyInfo=res
      this.list=res.contactUsVo
      this.initMap()
    })
  },
  destroyed() {
    this.map?.destroy();
  },
  methods: {
    initMap() {
      AMapLoader.load({
        key: "f9b3654d4844fffb1911b78b87a3ef23", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          this.map = new AMap.Map("gdMap", {
            // 设置地图容器id
            viewMode: "3D", // 是否为3D地图模式
            zoom: 4.2, // 初始化地图级别
            center: [108.270899, 34.300017], // 初始化地图中心点位置
            zoomEnable: false,//禁止缩放
            dragEnable: false,//禁止拖动
            mapStyle: 'amap://styles/darkblue'
          });
          this.infoList=[]
          this.list.forEach((v, index) => {
            // console.log(Array.form(v.coordinate))
            if(v.coordinate&&v.coordinate!=''){
            let pos=v.coordinate.substring(1,v.coordinate.length-1).split(', ')
            var marker = new AMap.Marker({
              position: new AMap.LngLat(pos[0], pos[1]),
              title: v.companyName,
              color: '#000',
              label:{
                content:v.companyName
              },
              // content:index==0?'总':'分',
              // title:v.iconLabel
            })
            marker.on('click', (e) => {
              this.clickMarket(index) // 
            })
            this.infoList.push(v)
            this.markList.push(marker)
            }
          })

          // 将创建的点标记添加到已有的地图实例：
          this.map.add(this.markList);
        })
        .catch((e) => {
          // console.log(e);
        });
    },
    clickMarket(index) {
      // console.log(index)
      this.curIndex = index
    }
  }
}
</script>
<style scoped>
#gdMap {
  width: 600px;
  height: 400px;
}
.concact-area {
  display: flex;
}
.right{
  margin: 0 auto;
  min-width: 500px;
  max-width: 700px;
}
.title {
  font-size: 20px;
  font-weight: bold;
  color: #fff !important;
}
.name{
  color: #fff !important;
  text-align: left;
  margin-bottom: 10px;
}
.para{
  text-align: left;
  margin-bottom: 10px;
}
.spe-para{
  color: rgba(0, 0, 0, 0)!important;
}
/* .para:first-of-type{
  margin-bottom: 30px;
} */
.info-box{
  min-height: 205px;
}
.spe-line{
  display: flex;
}
.spe-item{
  margin-right: 10px;
}
.spe-item img{
  width: 150px;
}
</style>