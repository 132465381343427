<template>
  <div class="hgbw-page-area">
    <Header></Header>
    <div class="middle-area">
      <pageBanner></pageBanner>
      <pageNavLine :leftList="navList" leftLabelKey="title" :leftCurrent="curActive" :infoList="navInfoList"
        @navClick="clickNavItem"></pageNavLine>
      <div class="common-clumb-min commonweb clearfix">
        <div class="clumb-title">
          <h3 class="cn font22">{{ titleList[curActive].label }}</h3>
          <!-- <h2 class="en font24">{{ titleList[curActive].subTitle }}</h2> -->
        </div>
      </div>
      <!-- 公司公告 -->
      <template v-if="navList[curActive] && navList[curActive].categoryOne == 1">
        <nav-line :list="typeList" :current="curType" @navClick="clickType"></nav-line>
        <div class="Download-list commonweb phonepadding">
          <ul>
            <li v-for="(item, index) in list" :key="index"> 
              <span class="down-text" @click.stop="viewFile(item)">
                <span>{{ item.title }}</span>
                <span>{{ item.createTime||('2024-02-02').substring(0,10) }}</span>
              </span>
               <a href="javascript:void(0)" :title="`${item.title}下载`" class="down-btn" @click.stop="openFile(item)"></a> </li>
          </ul>
        </div>
      </template>
      <!-- 投资者服务 -->
      <template v-if="navList[curActive] && navList[curActive].categoryOne == 2">
        <div class="contact-minbox commonweb padtb">
          <div class="contact-dl clearfix">
            <div class="infor">
              <h3 class="font24">华博科技有限公司</h3>
              <p class="PS clearfix">
                <span class="name">地址： </span> <span class="text"> <span style="white-space:normal;">{{
        companyInfo.companyAddress }}</span></span>
              </p>
              <p class="PS clearfix">
                <span class="name">电话： </span> <span class="text"> <span style="white-space:normal;">{{
        companyInfo.companyPhone }}</span></span>
              </p>
              <p class="PS clearfix">
                <span class="name">传真： </span> <span class="text"> {{ companyInfo.companyFax }}</span>
              </p>
              <p class="PS clearfix">
                <span class="name">邮箱： </span> <span class="text"> {{ companyInfo.companyEmail }}</span>
              </p>
              <div class="company-map" id="companyMap"></div>
            </div>
          </div>
        </div>
      </template>
      <!-- 法规政策 -->
      <template v-if="navList[curActive] && navList[curActive].categoryOne == 3">
        <div class="Pledge-min commonweb padtb img-content">
          <img :src="$BaseUrl + companyInfo.image" class="img-area" />
        </div>
      </template>
    </div>
    <Footer></Footer>
    <backToTop></backToTop>
  </div>
</template>
<script>
import { getNavList, getNotice, getInvestServer } from "@/api/investorRelations"
import AMapLoader from "@amap/amap-jsapi-loader";
import { getDicts } from "@/api/common"
import navLine from "@/components/navLine"
export default {
  components: { navLine },
  data() {
    return {
      navList: [],
      curActive: 0,
      categoryOneObject: {
        '1': '公司公告',
        '2': '投资者服务',
        '3': '政策法规'
      },
      list: [],
      curType: 0,
      typeList: [],
      companyInfo: {},
      navInfoList: [{
        name: '投资者关系'
      }],
      titleList: {
        0: { label: '公司公告', subTitle: 'COMPANY ANNOUNCEMENT' },
        1: { label: '投资者服务', subTitle: 'INVEOTOR SERVICES' },
        2: { label: '政策法规', subTitle: 'POLICIES REGULATIONS' }
      }
    }
  },
  async mounted() {
    let res = await getDicts('investor_relations')
    let resNT = await getDicts('notice_type')
    // this.initMap()
    this.categoryOneObject = {}
    res.forEach(v => {
      this.$set(this.categoryOneObject, v.dictValue, v.dictLabel)
    })
    this.typeList = resNT.map(v => {
      v.value = Number(v.dictValue)
      v.label = v.dictLabel
      return v
    })
    this.getNav()
  },
  methods: {
    getNav() {
      getNavList().then(res => {
        this.navList = res.map(v => {
          v.title = this.categoryOneObject[v.categoryOne]
          return v
        })
        if (this.$route.query.id != undefined) {
          let idx = this.navList.findIndex(t => { return t.categoryOne == this.$route.query.id })
          this.curActive = idx == -1 ? 0 : idx
        }
        console.log(this.navList)
        this.clickNavItem({ item: this.navList[this.curActive], index: this.curActive })
      })
    },
    clickNavItem({ item, index }) {
      this.curActive = index
      if (item.categoryOne == 1) {
        this.currentPage = 1
        // window.location.href='https://www.neeq.com.cn/products/neeq_listed_companies/related_announcement.html?companyCode=831308&typename=G'
        // getNotice(1).then(res => {
        //   this.list = res.rows

        // })
      } else {
        if (item.categoryOne == 2) {
          this.initMap()
        }
        getInvestServer(item.categoryOne).then(res => {
          this.companyInfo = res.result
        })
      }
    },
    clickType({ item, index }) {
      this.curType = index
       window.location.href='https://www.neeq.com.cn/products/neeq_listed_companies/related_announcement.html?companyCode=831308&typename=G'
      // getNotice(item.value).then(res => {
      //   this.list = res.rows
      // })
    },
    openFile(item) {
      // 获取heads中的filename文件名
      let that=this
      let xhr = new XMLHttpRequest();
        xhr.open("get", this.$BaseUrl + item.file, true);
        // xhr.setRequestHeader("Content-Type", `application/pdf`);
        xhr.responseType = "blob";
        xhr.onload = function () {
          if (this.status == 200) {
            //接受二进制文件流
            var blob = this.response;
            that.downloadExportFile(blob, item.title);
          }
        };
        xhr.send();
    },
    downloadExportFile(blob, fileName){
      let downloadElement = document.createElement("a");
      let href = blob;
      if (typeof blob == "string") {
        downloadElement.target = "_blank";
      } else {
        href = window.URL.createObjectURL(blob); //创建下载的链接
      }
      downloadElement.href = href;
      downloadElement.download = fileName;
      document.body.appendChild(downloadElement);
      downloadElement.click(); //点击下载
      document.body.removeChild(downloadElement); //下载完成移除元素
      if (typeof blob != "string") {
        window.URL.revokeObjectURL(href); //释放掉blob对象
      }
    },
    viewFile(item){
      window.open(this.$BaseUrl + item.file);
    },
    initMap() {
      AMapLoader.load({
        key: "f9b3654d4844fffb1911b78b87a3ef23", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          this.map = new AMap.Map("companyMap", {
            // 设置地图容器id
            viewMode: "3D", // 是否为3D地图模式
            zoom: 18, // 初始化地图级别
            center: [119.273907, 26.078144], // 初始化地图中心点位置
            zoomEnable: true,//禁止缩放
            dragEnable: true,//禁止拖动
          });
          var marker = new AMap.Marker({
            position: new AMap.LngLat(119.273907, 26.078144),
            title: '华博科技有限公司',
            color: '#000',
            label: {
              content: '华博科技有限公司'
            },
          })
          // 将创建的点标记添加到已有的地图实例：
          this.map.add(marker);
        })
        .catch((e) => {
          // console.log(e);
        });
    }
  }
}
</script>
<style scoped>
.img-content {
  display: flex;
  justify-content: center;
}

.img-box {
  display: flex;
  justify-content: center;
}

.company-map {
  width: 800px;
  margin: 0 auto;
  /* background: red; */
  height: 400px;
  margin-top: 20px;
}

.infor {
  width: 800px;
  margin: 0 auto;
}

.infor .font24 {
  text-align: center;
}
.Download-list li{
  padding-right: 110px!important;
}
.down-text{
  display: flex!important;
  width: 100%;
  align-items: center;
  justify-content: space-between
}
.down-text>span:first-of-type{
  display: inline-block;
    max-width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
</style>