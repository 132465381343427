<template>
  <div class="hgbw-page-area">
    <Header></Header>
    <div class="middle-area">
      <pageBanner :type="4"></pageBanner>
      <pageNavLine :leftList="navList" leftLabelKey="title" :leftCurrent="curActive" :infoList="navInfoList"
        @navClick="clickNavItem"></pageNavLine>
      <div class="common-clumb-min commonweb clearfix">
        <div class="clumb-title">
          <h3 class="cn font22">{{ titleList[curActive].label }}</h3>
          <!-- <h2 class="en font24">{{ titleList[curActive].subTitle }}</h2> -->
        </div>
      </div>
      <template v-if="navList[curActive] && navList[curActive].categoryOne == 1">
        <div class="Pledge-min commonweb padtb">
          <div class="Pledetext">
            <company-swiper :list="companyInfo.ContactUsList" ref="companySwiperRef"></company-swiper>
            <div class="concact-line">
              <div class="left">
                <p class="title">省内热线：{{ companyInfo.CustomerPhone }}</p>
                <p class="title">全国热线：{{ companyInfo.AbroadPhone }}</p>
                <p class="title">客服邮箱：{{ companyInfo.Email }}</p>
              </div>
              <div class="right" v-if="companyInfo.ImgVo && companyInfo.ImgVo.length <= 2">
                <div class="spe-item" v-for="(item, index) in companyInfo.ImgVo" :key="index">
                  <img :src="$BaseUrl + item.imgUrl" />
                  <p>{{ item.description }} </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <!--  -->
      <template v-if="navList[curActive] && navList[curActive].categoryOne == 2">
        <div class="Comprepairform commonweb padtb">
          <div class="Comprepairss">
            <div id="feedbackForm">
              <el-form :model="messageForm" label-width="80px" label-position="left" ref="form" :rules="rules">
                <el-form-item label="姓名" class="inputleft inputright" prop="name">
                  <el-input type="text" ref="formData_Title" v-model="messageForm.name" maxlength="200"
                    class=""></el-input>
                  <span class="help-block"></span>
                </el-form-item>
                <el-form-item label="手机" class="inputleft inputright" prop="phone">
                  <el-input type="text" ref="formData_Phone" v-model="messageForm.phone" maxlength="200"
                    class=""></el-input>
                  <span class="help-block"></span>
                </el-form-item>
                <el-form-item label="联系邮箱" class="inputleft inputright" prop="email">
                  <el-input type="text" ref="formData_Email" v-model="messageForm.email" maxlength="100"
                    class=""></el-input>
                  <span class="help-block"></span>
                </el-form-item>
                <el-form-item label="地址" class="inputleft inputright" prop="address">
                  <el-input type="text" ref="formData_Address" v-model="messageForm.address" maxlength="200"
                    class=""></el-input>
                  <span class="help-block"></span>
                </el-form-item>
                <el-form-item label="内容" prop="content">
                  <el-input ref="formData_Content" type="textarea" v-model="messageForm.content" class=""
                    :autosize="{ minRows: 4, maxRows: 8 }"></el-input>
                  <span class="help-block"></span>
                </el-form-item>
                <el-form-item>
                  <!-- @click="postSubmit" -->
                  <el-button type="primary" size="small" @click="submitForm">提交</el-button>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
      </template>
    </div>
    <Footer></Footer>
    <backToTop></backToTop>
  </div>
</template>
<script>
import { getNavList, getContactInfo, addConcact, getHelpCenter } from "@/api/concactUs"
import "../../assets/form/element-ui.css"
import companySwiper from "@/components/companySwiper"
export default {
  components: { companySwiper },
  data() {
    return {
      navList: [],
      navObject: {
        1: '分支机构',
        2: '在线留言',
        //3: '帮助中心'
      },
      curActive: 0,
      helpList: [],
      typeList: [{
        value: 1,
        label: '商务咨询'
      }, {
        value: 2,
        label: '技术咨询'
      }, {
        value: 3,
        label: '意见建议'
      }],
      curType: 0,
      companyInfo: {},
      messageForm: {},
      navInfoList: [{
        name: '分支机构'
      }],
      rules: {
        name: [{
          required: true,
          message: '请输入姓名',
          trigger: 'blur'
        }],
        phone: [{
          required: true,
          message: '请输入手机',
          trigger: 'blur'
        }, {
          pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
          message: "请输入正确的手机号",
          trigger: ["blur", "change"]
        }],
        email: [{
          required: true,
          message: '请输入邮箱',
          trigger: 'blur'
        }, {
          type: "email",
          message: "请输入正确的邮箱地址",
          trigger: ["blur", "change"]
        }],
        address: [{
          required: true,
          message: '请输入地址1',
          trigger: 'blur'
        }],
        content: [{
          required: true,
          message: '请输入内容',
          trigger: 'blur'
        }],
      },
      titleList: [{
        label: '分支机构',
        subTitle: 'CONTACT US'
      }, {
        label: '在线留言',
        subTitle: 'ONLINE MESSAGE'
      }],
      map: null
    }
  },
  mounted() {
    this.getNavList()
  },
  methods: {
    getNavList() {
      getNavList().then(res => {
        this.navList = res.map(v => {
          v.title = this.navObject[v.categoryOne]
          return v
        }).filter(v => { return v.title != undefined })
        if (this.$route.query.title) {
          let idx = this.navList.findIndex(t => { return t.title == this.$route.query.title })
          this.curActive = idx == -1 ? 0 : idx
        }
        this.clickNavItem({ item: this.navList[this.curActive], index: this.curActive })
      })
    },
    clickNavItem({ item, index }) {
      this.curActive = index
      if (item.categoryOne == 1) {
        getContactInfo().then(res => {
          this.companyInfo = res
          // this.$nextTick(() => {
          //   if (this.companyInfo.ContactUsList && this.companyInfo.ContactUsList.length != 0) {
          //     this.$refs.companySwiperRef.init()
          //   }
          // })
        })
      } else if (item.categoryOne == 3) {
        // 帮助中心
        getHelpCenter().then(res => {
          this.helpList = res
        })
      }
    },
    changeType(index) {
      this.curType = index
    },
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let params = {
            categoryOne: 2,
            categoryTwo: this.typeList[this.curType].value,
            ...this.messageForm
          }
          addConcact(params).then(res => {
            // alert('提交成功!')
            this.$message.success('提交成功!')
            this.messageForm = {}
          })
        }
      })
    },
  }
}
</script>
<style scoped>
.company-box {
  margin-bottom: 10px;
}

.concact-line {
  display: flex;
  justify-content: flex-end;
  /* justify-content: space-between; */
  align-items: space-around;
  margin-top: 40px;
}
.concact-line .left{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-right: 40px;
}
.concact-line .title {
  font-size: 14px;
  color: #929292;
  /* margin-bottom: 20px; */
}

.concact-line .right {
  display: flex;
}

.img-list {
  display: flex;
}

.spe-item {
  margin-right: 10px;
}

.spe-item img {
  width: 130px;
}

.el-form-item__content .edui-default {
  line-height: 20px;
}

.el-form-item {
  margin-bottom: 30px;
}

.el-form-item__content {
  text-align: center;
}

.el-button--primary {
  background-color: #d82019;
  border-color: #d82019;
}

.el-button--primary:focus,
.el-button--primary:hover {
  background: #c5241e;
  border-color: #c5241e;
}

.el-input.is-active .el-input__inner,
.el-input__inner:focus,
.el-textarea__inner:focus {
  border-color: #d82019;
}

#feedbackForm {
  position: relative;
}

@media (min-width:1000px) {
  .inputleft {
    width: 48%;
  }

  .inputright {
    margin-right: 40px;
  }

  .el-button--small,
  .el-button--small.is-round {
    padding: 15px 15px;
    width: 340px;
    font-size: 15px;
    font-weight: bold;
  }

  .inputtop {
    position: absolute;
    right: 0;
    top: 0;
  }

  .inputbottom {
    position: absolute;
    right: 0;
    top: 70px;
  }

  .el-form-item__label {
    line-height: 50px;
  }

  .el-input__inner {
    height: 50px;
    line-height: 50px;
  }

  .el-textarea__inner {
    min-height: 120px !important;
    height: 120px !important
  }
}

@media (max-width:1000px) {

  .el-button--small,
  .el-button--small.is-round {
    width: 100%;
  }
}
</style>