<template>
    <div>
        <Header></Header>
        <div class="swiper-area">
            <div class="indexbanner swiper-container swiper-container-horizontal banner swiper-banner">
            <div class="swiper-wrapper clearfix imgae-same-size"
                style="transform: translate3d(-6076px, 0px, 0px); transition-duration: 0ms;">
                <div class="swiper-slide" v-for="(item, index) in bannerList" :key="index"><img
                        :src="$BaseUrl + item.image"></div>
            </div>
            <div class="swiper-pagination visible-md hidden-sm hidden-xs"></div>
        </div>
        <div class="swiper-word-box">
            <div class="swiper-word">互联网+职业教育</div>
            <div class="swiper-tip">综合运营服务商+人才运营服务商</div>
        </div>
        </div>


        <section>


            <!--产品展示-->
            <div class="indxProduct commonweb padtb">
                <div class="index-text center wow fadeInDown">
                    <h2 class="cn font30 ">我们的业务</h2>
                    <!-- <h3 class="en font24 ">OUR SERVICES</h3> -->
                </div>
                <div class="inxprod-list padtb service-list">
                    <div class="service-item wow" v-for="(item, index) in serverList" :key="index"
                            :class="(index % 4 == 0 || index % 4 == 1) ? 'fadeInLeft' : 'fadeInRight'" @click="goService(item)">
                            <div class="service-img">
                                <img :src="item.bg"/>
                            </div>
                            <div class="service-title">
                                {{ item.title }}
                            </div>
                            <div class="service-hover">
                                <span class="service-hover-title">{{ item.title }}</span>
                                <div v-html="item.desc" class="service-hover-content"></div>
                            </div>
                    </div>
                </div>
            </div>

            <!--解决方案-->
            <!-- <div class=" inxSolution padtb home-solutionbg">
                <div class="commonweb">
                    <div class="index-text center wow fadeInDown">
                        <h2 class="cn font30 cffffff">服务案例</h2>
                    </div>
                    <div class="inxSolut-wrapper">
                        <div class=" inxSolut-min">
                            <ul class="clearfix">

                                <li class="wow col-md-avg-5 col-sm-avg-5" v-for="(item, index) in categoryList"
                                    :key="index" :class="index == categoryList.length - 1 ? 'slideInRight' : 'slideInLeft'">
                                    <a :href="`/product?id=${item.categoryId}`">
                                        <div class="solut-img"> <img :src="$BaseUrl + item.image" alt="images" /> </div>
                                        <div class="solut-text">
                                            <h3 class="cn font16">{{ item.categoryName }}</h3>
                                        </div>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="index-more center wow fadeInUp home-more"> <a href="/product">了解更多</a></div>
                </div>
            </div> -->



            <!--新闻资讯--->
            <div class="indxNewsmin commonweb padtb">
                <div class="index-text center wow fadeInDown">
                    <h2 class="cn font30 ">新闻资讯</h2>
                    <!-- <h3 class="en font24 ">NEWS CENTER</h3> -->
                </div>
                <div class="indxNews-wraper ">
                    <div class="inxnews-list">
                        <ul class="clearfix home-list imgae-same-size">
                            <li class="wow col-md-3 col-sm-3 col-xs-6" v-for="(item, index) in newsList" :key="index">
                                <a :href="'/newsDetail?id=' + item.newsId" title="item.title">
                                    <div class="tiothumb"> <img :src="$BaseUrl + item.image" :alt="item.title" /> </div>
                                    <div class="tioinfor">
                                        <p class="text"> {{ item.title }} </p>
                                        <span class="date">{{ item.publishTime }}</span>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="index-more center wow fadeInUp "> <a href="/news">了解更多</a></div>
            </div>

            <!--关于我们--->
            <div class="indxAboutus clearfix">
                <div class="indxAboutus-line">
                    <div class="indxAboutus-left">
                    <img src="../../assets/images/aboutUs3.png"/>
                </div>
                <div class="indxAboutus-right">
                    <div class="indxAboutus-right-top">
                          <span class="indxAboutus-right-top-title">关于我们</span>
                          <div v-html="aboutContent" class="overHidden5 indxAboutus-right-top-content"></div>
                          <div class="index-more wow fadeInUp"> <a href="/aboutUs" class="moreBtn">了解更多</a> </div>
                    </div>
                    <div class="indxAboutus-right-bottom">
                        <div class="indxAboutus-right-bottom-left" >
                            <img src="../../assets/images/aboutUs2.png"/>
                        </div>
                        <div class="indxAboutus-right-bottom-right">
                            <span>互联网+职业教育</span>
                            <span>综合运营服务商</span>
                             <span>人才运营服务商</span>
                        </div>
                    </div>
                </div>
                </div>
            </div>


            <!--合作伙伴-->
            <div class=" inxPartner  padtb">
                <div class="commonweb">
                    <div class="index-text center wow fadeInDown">
                        <h2 class="cn font30">企业文化</h2>
                        <!-- <h3 class="en font24">COMPANY CULTURE</h3> -->
                    </div>
                    <div class="inxpart-list">
                        <div class="partner-wrapper homebanner">
                            <div class="cultrue-list">
                                <div class="culture-item" v-for="(item,index) in cultureList" :key="index">
                                 <div class="culture-img">
                                    <img :src="item.img"/>
                                </div>
                                <div class="culture-title">
                                    {{ item.title }}
                                </div>
                                <div class="culture-text" v-html="item.content">
                                </div>
                             </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>


            <div class=" inxPartner  padtb">
                <div class="commonweb">
                    <div class="index-text center wow fadeInDown">
                        <h2 class="cn font30">部分合作伙伴</h2>
                        <!-- <h3 class="en font24">COMPANY CULTURE</h3> -->
                    </div>
                    <div class="inxpart-list">
                        <div class="partner-wrapper homebanner">
                            <div class="partner-list">
                            <vueSeamlessScroll :data="partnerList[0]" :class-option="defaultOption1">
                                <div class="lpartner-list">
                                    <div v-for="(item,index) in partnerList[0]" :key="index" class="partner2-box">
                                    <img :src="$BaseUrl + item.imgUrl" class="partner-img"/>
                                </div>
                                </div>
                            </vueSeamlessScroll>
                        </div>
                        <div class="partner-list">
                            <vueSeamlessScroll :data="partnerList[1]" :class-option="defaultOption2">
                                <div class="lpartner-list">
                                    <div v-for="(item,index) in partnerList[1]" :key="index" class="partner2-box">
                                    <img :src="$BaseUrl + item.imgUrl" class="partner-img"/>
                                </div>
                                </div>
                            </vueSeamlessScroll>
                        </div>
                        <div class="partner-list">
                            <vueSeamlessScroll :data="partnerList[2]" :class-option="defaultOption3">
                                <div class="lpartner-list">
                                    <div v-for="(item,index) in partnerList[2]" :key="index" class="partner2-box">
                                    <img :src="$BaseUrl + item.imgUrl" class="partner-img"/>
                                </div>
                                </div>
                            </vueSeamlessScroll>
                        </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
        <Footer></Footer>
        <!--PC端置顶-->
        <backToTop></backToTop>

    </div>



</template>
<script>
import maoBox from "@/components/maoBox"
import { homeCategory, homeAboutus, getBanner, homeNews, getPartnerList,homeProducts } from "@/api/index.js";
import { getServiceList } from '@/api/service'
import vueSeamlessScroll from "vue-seamless-scroll";
export default {
    components: { maoBox,vueSeamlessScroll },
    data() {
        return {
            categoryOneList: [],
            oneGoodsList: [],
            bannerList: [{ imgUrl: '' }],
            aboutContent: '',//关于我们
            newsList: [],//新闻中心
            productList: [],
            categoryList: [],
            bannerList: [],
            serverList: [],
            partnerList: [[],[],[]],
            serviceInfoList:{
                '主营业务':"<p style='text-align:center;'>继续教育</p><p style='text-align:center;'>职业技能培训</p><p style='text-align:center;'>机构内训</p><p style='text-align:center;'>专项培训</p><p style='text-align:center;'>学历教育咨询服务</p><p style='text-align:center;'>人力资源服务</p>",
                '我们的优势':"<p style='text-align:center;'>零投资  零维护</p><p style='text-align:center;'>一体化应用与一站式培训全过程服务</p><p style='text-align:center;'>安全保障</p><p style='text-align:center;'>支持大规模并发的在线学习和考试</p><p style='text-align:center;'>课程丰富、师资雄厚</p><p style='text-align:center;'>独立透明的报名缴费结算体系</p>",
                '产品应用':"<p style='text-align:center;'>职业培训管理解决方案</p><p style='text-align:center;'>继续教育公共服务平台</p><p style='text-align:center;'>线上培训整体解决方案</p><p style='text-align:center;'>线下培训整体解决方案</p><p style='text-align:center;'></p><p style='text-align:center;'>线上+线下培训整体解决方案</p>"
            },
            cultureList:[{
                img:require('../../assets/images/culture1.png'),
                title:'公司使命',
                content:"<p style='text-align:center;'>成为中国教育服务业的</p><p style='text-align:center;'>创新者和贡献者</o>"
            },{
                img:require('../../assets/images/culture2.png'),
                title:'公司愿景',
                content:"<p style='text-align:center;'>网络改变教育</p>"
            },{
                img:require('../../assets/images/culture3.png'),
                title:'核心价值观',
                content:"<p style='text-align:center;'>专注 奋斗 感恩 共享</p>"
            },{
                img:require('../../assets/images/culture4.png'),
                title:'华博人精神',
                content:"<p style='text-align:center;'>激情 面对 解决 学习 吸收 融合</p><p style='text-align:center;'>讲认真 抓落实 求合作</p>"
            }],
            defaultOption1: {
        step: 0.4, // 数值越大速度滚动越快
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 2, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动)direction => 0/1                                
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 100 // 单步运动停止的时间(默认值1000ms)
      },
      defaultOption2: {
        step: 0.5, // 数值越大速度滚动越快
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 2, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动)direction => 0/1                                
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 100 // 单步运动停止的时间(默认值1000ms)
      },
      defaultOption3: {
        step: 0.6, // 数值越大速度滚动越快
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 2, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动)direction => 0/1                                
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 100 // 单步运动停止的时间(默认值1000ms)
      },
        };
    },
    async created() {
        let res = await getBanner()
        this.bannerList = res.rows
        this.getHomeData()
    },
    mounted() {
        setTimeout(() => {
            this.$nextTick(() => {
                var banner = new Swiper('.banner', {
                    autoplay: true,
                    autoplay: {
                        delay: 4000,
                        disableOnInteraction: false,
                    },
                    pagination: {
                        el: '.banner .swiper-pagination',
                        clickable: true
                    }
                });
                new WOW().init();
            })
        }, 1000);
    },
    methods: {
        getHomeData() {
            homeAboutus().then(res => {
                if (res.content) {
                    this.aboutContent = res.content.replace(/<img.*?>/g, "")
                }
            })
            homeNews().then(res => {
                this.newsList = res.rows || []
                if(this.newsList.length>4){
                    this.newsList=this.newsList.splice(0,4)
                }
            })
            homeCategory({sortType:2}).then(res => {
                this.categoryList = res.rows.slice(0, 5)
            })
            getServiceList().then(res => {
                this.serverList = Object.keys(res).map((v, index) => {
                    return {
                        bg: require(`../../assets/images/service${index % 3 + 1}.png`),
                        desc:this.serviceInfoList[v],
                        title: v
                    }
                }).reverse()
            })
            getPartnerList().then(res => {
                this.partnerList = (res.rows || []).reduce((res,curV,curI)=>{
                    let idx=curI%3
                    res[idx].push(curV)
                    return res
                },[[],[],[]])
                // if (this.partnerList.length != 0) {
                    // this.$nextTick(() => {
                    //     marqueeStart(1, "left");
                    // })
                // }
            })
        },
        goDetail(item) {
            this.$router.push({ path: '/productDetail?id=' + item.productsId })
        },
        goProductList(item) {
            this.$router.push({ path: '/product?id=' + item.categoryId })
        },
        goAboutUs() {
            this.$router.push({ path: '/aboutUs' })
        },
        goService(item){
            this.$router.push({path:`/service?name=${item.title}`})
        }
    },
};
</script>
<style>
.swiper-pagination-bullet-active {
    background: #d82019;
}

.swiper-pagination-bullet {
    width: 12px;
    height: 12px;
}

.swiper-slide {
    max-height: 580px;
}

.overHidden5 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    text-overflow: ellipsis;
    overflow: hidden;

}
</style>

<style scoped>
.swiper-item {
    width: 100%;
}

.homeAboutus .container {
    max-width: 1340px;
}

.homeAboutus .title-head {
    max-width: 1200px;
    cursor: pointer;
}
.swiper-banner{
    min-height: 500px;
}
.newsLine {
    margin-bottom: 0px !important;
}

.newsItem {
    width: 100% !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
}

.overHidden {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box !important;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.tiothumb {
    width: 270px;
    height: 290px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center
}
.tiothumb img{
    width: 270px;
    height: 290px;
    object-fit: cover;
}
.solut-img {
    width: 100%;
    height: 146px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.solut-img img{
    width: 100%;
    height: 146px;
    object-fit: cover;
}
.partner-box{
    width: 199px;
    height: 100px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}
.partner-box img{
    width: 199px;
    height: 100px;
    object-fit: cover;
}

/*  */
.indxAboutus{
    width: 100%;
    /* height: 737px; */
    padding: 70px 0; 
    box-sizing: border-box;
    background-image: url('../../assets/images/bg.png');
    background-size: cover;
    max-width: 1920px;
    margin: 0 auto;
}
.indxAboutus-line{
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
}
.indxAboutus-left{
    width:500px;
    height: 440px;  
}
.indxAboutus-left img{
        width:500px;
        height: 440px;
        object-fit: cover;
    }
.indxAboutus-right{
    width: 680px;
    height: 440px;
}
.indxAboutus-right-top{
    width: 685px;
    /* height: 275px; */
    height: 265px;
    margin-bottom: 10px;
    background-image: url('../../assets/images/aboutUs.png');
    background-size: 100% 100%;
    padding: 20px 50px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.indxAboutus-right-top .moreBtn{
     height: 40px;
     line-height: 40px;
     padding: 0 26px;
     color: rgba(255,255,255,0.8);
     border: 1px solid rgba(255,255,255,0.8);
     /* background: rgba(255,255,255,0);
border-radius: 31px;
border: 1px solid #FFFFFF; */
}
.indxAboutus-right-top .indxAboutus-right-top-title{
    font-family: Adobe Heiti Std;
font-weight: normal;
font-size: 28px;
color: #fff;
}
.indxAboutus-right-top .indxAboutus-right-top-content{
    font-weight: 400;
font-size: 12px;
color: #fff!important;

}
.indxAboutus-right-bottom{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.indxAboutus-right-bottom-left,.indxAboutus-right-bottom-right{
    width: 330px;
    height: 165px;
}
.indxAboutus-right-bottom-left img{
    width: 330px;
    height: 165px;
    object-fit: cover;
}
.indxAboutus-right-bottom-left img{
    width: 330px;
    height: 165px;
    object-fit: cover;
}
.indxAboutus-right-bottom-right{
    background: #385E8F;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.indxAboutus-right-bottom-right span{
    font-family: Adobe Heiti Std;
font-weight: normal;
font-size: 24px;
/* color: #0B1733; */
color: #fff;
margin-bottom: 15px;
user-select: none;
}
.indxAboutus-right-bottom-right span:nth-child(3){
    margin-bottom: 0;
}
/* 我们的服务 */
.service-list{
    display: flex;
    justify-content: space-around;
    align-items: center;
}
.service-item{
    width: 387px;
    height: 322px;
    position: relative;
    cursor: pointer;
    overflow: hidden;
}
.service-img{
    width: 100%;
    height: 322px;
    object-fit: cover;
}
.service-title{
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 73px;
    line-height: 73px;
    color: #fff;
font-size: 20px;
text-align: center;
}
.service-hover{
    position: absolute;
    height: 0;
    top: 0;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.service-hover-title{
    font-family: Source Han Sans SC;
font-weight: bold;
font-size: 28px;
line-height: 30px;
color: #FFFFFF;
margin-bottom: 20px;
}
.service-hover-content{
    font-weight: 400;
font-size: 16px;
color: #FFFFFF;
}
.service-item:hover .service-hover{
    height: 322px;
    padding: 50px 60px;
    animation-name: serviceItemHover;
        animation-duration: 0.3s;
        animation-iterationcount: 1;
        background: #265FDF;
        /* z-index: 2; */
}
.service-item:hover .service-hover-title,.service-item:hover .service-hover-content{
    animation-name: serviceItemHoverColor;
    /* animation-delay: 2s; */
        animation-duration: 1s;
        animation-iterationcount: 1;
        opacity: 1;
        /* z-index: 2; */
}
@keyframes serviceItemHover {
  from {
    height: 0;
  }
  33% {
    height: 108px;
  }
  66% {
    height: 216px;
  }
  to {
    height: 322px;
  }
}
@keyframes serviceItemHoverColor{
    from {
    opacity: 0;
  }
  80% {
    opacity: 0.9;
  }
  90%{
    opacity: 0.95;
  }
  to {
    opacity: 1;
  }
}
.swiper-area{
    position: relative;
}
.swiper-word-box{
    position: absolute;
    top: 50%;
    z-index: 2;
    left: 50%;
    width: 1200px;
    margin-left: -600px;
    margin-top:-70px;
}
.swiper-word{
    font-size: 60px;
    font-weight: bold;
    color: #fff;
    line-height: 80px;
}
.swiper-tip{
    font-size: 40px;
    font-weight: bold;
    color: #fff;
    line-height: 60px;
}
/* 企业文化 */
.inxPartner{
    padding: 60px 0;
}
.cultrue-list{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.culture-item{
    width: 24%;
    max-width: 262px;
height: 267px;
background: #FFFFFF;
box-shadow: 0px 0px 20px 0px rgba(89,93,215,0.2);
display: flex;
flex-direction: column;
padding-top: 40px;
align-items: center;
user-select: none;
}
.culture-img{
    width: 70px;
    height: 70px;
}
.culture-title{
    font-weight: 400;
font-size: 22px;
color: #527BE3;
line-height: 40px;
margin:20px 0
}
.culture-content{
    font-weight: 400;
font-size: 17px;
color: #666666;
line-height: 33px;
}
.partner-list{
    display: flex;
    margin-bottom:10px
}
.lpartner-list{
    display: flex;
    width: 100%;
}
.partner2-box{
  margin:0 20px;
  background-color: #fff;
  min-width: 300px;
  display: inline-block;
}
.partner-img{
    width: 300px;
    height: 80px;
    object-fit: contain;

}
</style>