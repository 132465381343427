<template>
    <div class="newshare bdsharebuttonbox " id="newshare"> 分享到：
        <a href="javascript:void(0)" class="bds_tsina txinlang" data-cmd="tsina" title="分享到新浪微博" @click="shareToWeiBo"></a>
        <!-- <a href="javascript:;" class="bds_weixin tweixin" data-cmd="weixin" title="分享到微信"></a> -->
        <a href="javascript:void(0)" class="tQQ bds_sqq" data-cmd="sqq" title="分享到QQ好友" @click="shareToQQ"></a>
    </div>
</template>
<script>
export default {
    props:{
        title:{
            type:String,
            default:''
        },
        pic:{
            type:String,
            default:''
        }
    },
    data() {
        return {
           url:''
        }
    },
    mounted(){
        this.url=this.$route.fullPath
    },
    methods: {
        shareToQQ() {
            let url = encodeURIComponent(this.url)
            let title = encodeURIComponent(this.title)
            let pic = encodeURIComponent(this.pic)
            window.open(
                `https://connect.qq.com/widget/shareqq/index.html?url=${url}&title=${title}&source=${url}&desc=${title}&pics=${[pic]}`)
        },
        shareToWeiBo() {
            // &pic=&appkey=&sudaref=
            let url = encodeURIComponent(this.url)
            let title = encodeURIComponent(this.title)
            let pic = encodeURIComponent(this.pic)
            window.open(`https://service.weibo.com/share/share.php?url=${url}&title=${title}&pic=${pic}`)
        }
    }
}
</script>