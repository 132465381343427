import request from '@/utils/request'

// 关于我们
export function homeAboutus() {
    return request({
        url: '/hbgwOnstage/homePage/aboutUs',
        method: 'get'
    })
}
// 新闻
export function homeNews() {
    return request({
        url: '/hbgwOnstage/homePage/news',
        method: 'get'
    })
}
// 产品
export function homeProducts() {
    return request({
        url: '/hbgwOnstage/homePage/products',
        method: 'get'
    })
}
// 分类
export function homeCategory(params) {
    return request({
        url: '/hbgwOnstage/homePage/category',
        method: 'get',
        params:params
    })
}
// 联系我们
export function homeContactUs() {
    return request({
        url: '/hbgwOnstage/homePage/contactUs',
        method: 'get'
    })
}
// 轮播图
export function getBanner() {
    return request({
        url: '/hbgwOnstage/homePage/carousel',
        method: 'get'
    })
}
// 合作伙伴
export function getPartnerList() {
    return request({
        url: '/hbgwOnstage/homePage/partnerList',
        method: 'get'
    })
}
// 查询
export function homeQuery(data) {
    return request({
        url: '/hbgwOnstage/homePage/query',
        method: 'get',
        params:data
    })
}