<template>
    <div class="text-center padding-20 page-list">
        <ul class="pagination">
            <li :class="judgeNowPage(null, 0)" @click="changePage(current-1)">
                <a href="javascript:void(0)">&laquo;</a>
            </li>
            <li v-for="(item, index) in pages" :key="index" :class="judgeNowPage(index, 1)" @click="changePage(index+1)">
                <a href="javascript:void(0)">{{ item }}</a>
            </li>
            <li  :class="judgeNowPage(null, 2)" @click="changePage(current+1)">
                <a href="javascript:void(0)">&raquo;</a>
            </li>

        </ul>
    </div>
</template>
<script>
export default {
    props: {
        pages: {
            type: Number,
            default: 0
        },
        current: {
            type: Number,
            default: 0
        }
    },
    methods: {
        judgeNowPage(index, type) {
            switch (type) {
                case 0:{
                    if(this.current==1){
                        return 'disabled'
                    }else{
                        return ''
                    }
                    break;
                }
                case 1: {
                    if (index == (this.current - 1)) {
                        return 'active'
                    } else {
                        return ''
                    }
                    break;
                }
                case 2:{
                    if(this.current==this.pages){
                        return 'disabled'
                    }else{
                        return ''
                    }
                    break;
                }
            }
        },
        changePage(index) {
            if(index==0||(index)>this.pages)return
            this.$emit('pageChange', index)
        }
    }
}
</script>
<style scoped>
.disabled >a{
    cursor:not-allowed!important
}
</style>