import request from '@/utils/request'

export function indexInitTwo() {
    return request({
        url: '/api/index/indexInitTwo',
        method: 'get'
    })
}
// 根据字典类型查询字典数据信息
export function getDicts(dictType) {
    return request({
      url: '/system/dict/data/type/' + dictType,
      method: 'get'
    })
  }