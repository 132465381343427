<template>
  <div class="hgbw-page-area">
    <Header></Header>
    <div class="middle-area">
      <pageBanner :type="3"></pageBanner>
      <pageNavLine :leftList="navList" leftLabelKey="categoryName" :leftNavShow="false" :leftCurrent="curActive" :infoList="navInfoList"
        @navClick="clickNavItem"></pageNavLine>
      <div class="newscenter-detail commonweb padtb">
        <div class="news-detail-title">
          <span class="date font22">{{ info.createTime ? info.createTime.substring(0, 10) : '' }}</span>
          <h2 class="font24">{{ info.productName }}</h2>
          <pageShare :title="info.productName" :pic="$BaseUrl + info.productImage"></pageShare>
        </div>

        <div class="padding-top-30 padding-bottim-30">
          <div class="text-center detail-img">
            <img alt="images" :src="$BaseUrl + info.productImage" />
          </div>
        </div>


        <div class="news-datail-content padtb">
          <div v-html="info.productIntro" class="html-area"></div>
        </div>
      </div>
    </div>
    <Footer></Footer>
    <backToTop></backToTop>
  </div>
</template>
<script>
import { getProductDetail, getCategoryNav } from "@/api/product"
import pageShare from "@/components/pageShare"
export default {
  components: { pageShare },
  data() {
    return {
      navList: [{
        categoryName: '典型案例',
        value: 0
      }],
      info: {},
      navInfoList: [{
        name: '服务案例',
        url: '/product?id=1'
      }, {
        name: '案例详情'
      }],
      curActive: 0
    }
  },
  async mounted() {
    // await this.getNav()
    this.getDetail(this.$route.query.id)
    // this.$nextTick(()=>{
    //   this.$refs.pageNav.curActive=-1
    // })
  },
  methods: {
    async getNav() {
      let res = await getCategoryNav({sortType:2})
      // .then(res => {
      this.navList = res
      // })
    },
    getDetail(id) {
      getProductDetail(id).then(res => {
        this.info = res
        let idx = this.navList.findIndex(t => { return t.categoryId == this.info.categoryId })
        this.curActive = idx
      })
    },
    clickNavItem({ item, index }) {
      this.$router.push({ path: '/product?id=1&twoId=' + item.categoryId })
    }
  }
}
</script>
<style scoped>
.productName .main-titles-head {
  max-width: 100%;
}

.html-area {
  max-width: 1140px;
  margin: 0 auto;
  white-space: pre-wrap;
  /* min-height:100vh */
}

.detail-img {
  width: 400px;
  height: 200px;
  overflow: hidden;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.detail-img img {
  max-width: 400px;
  height: 200px;
}
.news-datail-content /deep/ a{
   color: #1890ff;
}
</style>