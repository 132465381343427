<template>
  <div class="hgbw-page-area">
    <Header></Header>
    <div class="middle-area">
      <pageBanner :type="2"></pageBanner>
      <pageNavLine :leftList="navList" leftLabelKey="title" :leftCurrent="curActive" :infoList="navInfoList"
        @navClick="clickNavItem"></pageNavLine>
      <div class="common-clumb-min commonweb clearfix">
        <div class="clumb-title" v-if="navList[curActive]">
          <h3 class="cn font22">{{ navList[curActive].title }}</h3>
          <!-- <h2 class="en font24">{{ navSubTitle[navList[curActive].title] }}</h2> -->
        </div>
      </div>
      <!-- 富文本 -->
      <section class="w3l-recent-work-hobbies" id="services">
        <div class="recent-work editContent servicetName" v-if="navList[curActive]&&navList[curActive].title!='产品应用'">
          <div v-html="showItem.content" class="html-area ql-editor"></div>
        </div>
        <div class="recent-work editContent servicet-product-area" v-if="navList[curActive]&&navList[curActive].title=='产品应用'">
           <div class="service-product-item" v-for="(item,index) in productService" :key="index" :class="{'reverse-line':index%2!=0}">
              <div class="service-product-img">
                  <img :src="item.img">
              </div>
              <div class="service-product-content">
                   <div class="service-product-title">{{ item.title }}</div>
                   <div class="service-product-desc">{{ item.content }}</div>
              </div>
           </div>
        </div>
      </section>
    </div>
    <Footer></Footer>
    <backToTop></backToTop>
  </div>
</template>
<script>
import { getServiceList } from '@/api/service'
export default {
  data() {
    return {
      navList: [],
      curActive: 0,
      infoObject: {},//产品与服务的全部数据
      showItem: {},
      navInfoList: [{
        name: '我们的业务'
      }],
      navSubTitle:{
        '产品应用':'PRODUCT APPLICATION',
        '我们的优势':'OUR ADVANTAGEa',
        '主营业务':'MAIN BUSINESS'
      },
      productService:[{
        img:require('../../assets/images/serviceP1.png'),
        title:'职业培训管理解决方案',
        content:'为满足政府机关/行业主管开展职业能力提升行动与终身职业教育要求，助力加速知识型、技能型、创新型人才队伍建设，华博科技建设职业培训管理平台，为各类培训主体的开班、培训、发证、补贴申请、补贴发放环节提供有力支持，实现参培人员与补贴人员实名制。引入AI技术通过数据分析和学习算法，实时监测学员的学习进度和表现、鉴定补贴与培训补贴多层严格比对，推进职业培训和各项见证补贴规范开展、参培可享、发放有据、高效办理和全流程信息化。'
      },{
        img:require('../../assets/images/serviceP2.png'),
        title:'继续教育公共服务平台',
        content:'为推进专业技术人员继续教育与公共服务、信息化管理的有效整合，提高继续教育申报审核工作效率，华博科技为继续教育领域的政府机关、事业单位、行业协会等客户，搭建集分权管理、分级统筹、培训监管、证书认定于一体，通过A赋能和信息化手段，实现维续教育对象和活动全面管理的公共服务平台。借助AI技术可以实时监测培训的进行，对培训内容、师资质量等进行评估，对培训结果进行电子化档案管理，确保培训全过程的质量和合规性。还支持省市区多级数据统计功能，通过数据的集中统计和分析，帮助主管部门了解继续教育领域的整体情况，并进行决策和规划。'
      },{
        img:require('../../assets/images/serviceP3.png'),
        title:'线上培训整体解决方案',
        content:'为了满足不同培训主体的个性化需求，华博科技设计的基于云端架构的SaaS平台提供了高度的可定制性和灵活性。每个培训主体都可以拥有一个独立域名的培训网校，这不仅能够塑造独特的品牌形象，还能够增强用户的归属感和信任度。平台还提供了从报名到出具证明的全流程培训服务。学员可以通过平台完成报名缴费、在线学习、考试，最终获得电子证明。这种一站式的培训服务模式大大简化了培训流程，提高了培训效率，也为培训主体提供了零门槛的便捷服务方式。'
      },{
        img:require('../../assets/images/serviceP4.png'),
        title:'线下培训整体解决方案',
        content:'培训云管家是一款简化管理流程、节约管理成本、提高管理效率、优化过程体验的线下培训信息化管理轻应用，产品为开展面授培训的组织者、管理者产品打通耗费人力的线下管理环节，提供在线报名、班级通讯录、培训日程、实时提醒、作业催交、问卷调查等功能，采集培训过程数据，辅助管理员轻松管理“训前-训中-训后”。'
      },{
        img:require('../../assets/images/serviceP5.png'),
        title:'线上+线下培训整体解决方案',
        content:'华博科技聚焦培训服务，面向开展线上、线下培训的培训机构、用人单位、学校等客户，提供基于SAAS(软件即服务)模式的包含“平台+内容+服务+管理”的整体解决方案，帮助机构客户轻松开展线上、线下培训。平台提供从报名管理、培训管理、运营管理、财务管理到客服管理等环节的信息化服务工具，降低客户进入个应用领域的门槛、节约培训管理工作的成本，保障培训业务顺利开展。'
      }]
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      getServiceList().then(res => {
        this.navList = Object.keys(res).map(v => {
          return {
            title: v
          }
        }).reverse()
        this.infoObject = res
        // 存在参数
        if (this.$route.query.name) {
          let idx = this.navList.findIndex(t => { return t.title == this.$route.query.name })
          this.curActive = idx == -1 ? 0 : idx
        }
        this.clickNavItem({ item: this.navList[this.curActive], index: this.curActive })
      })
    },
    clickNavItem({ item, index }) {
      this.curActive = index
      this.showItem = this.infoObject[item.title][0]
    }
  }
}
</script>
<style scoped>
.html-area {
  max-width: 1140px;
  margin: 0 auto;
  white-space: pre-wrap;
  /* min-height:100vh */
}

.servicetName {
  padding-top: 30px;
}

.servicet-product-area{
  margin: 0 auto;
  max-width: 1185px;
  background-image: url('../../assets/images/service-bg.png');
  /* background-size: 102% 95%; */
  background-position: 0 215px;
}

.service-product-item{
  width: 100%;
  height: 215px;
  display: flex;
  align-items: center;
  padding: 15px 40px;
  box-sizing: border-box;
}
.reverse-line{
  flex-direction: row-reverse;
}
.service-product-img{
  width: 246px;
  height: 215px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
}
.service-product-img img{
  width: 246px;
  height: 148px;
}
.service-product-content{
  padding: 0 20px 0 35px;
  box-sizing: border-box;
}
.service-product-title{
  font-weight: 400;
font-size: 26px;
color: #333333;
line-height: 48px;
margin-bottom: 15px;
}
.service-product-desc{
  font-weight: 400;
font-size: 16px;
color: #444444;
}
.servicetName /deep/ img{
  max-width: 1140px!important;
  object-fit: contain;
  margin: 0 auto;
}
.servicetName /deep/ p{
  font-size: 18px;
}
</style>