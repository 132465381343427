export const language = { 
    freeShipping: '所有订单满99元免运费',
    home:'首页',
    shop:'商品',
    contact:'联系我们',
    blog:'资讯',
    departments:'分类',
    categories:'所有分类',
    need:'请输入商品名称',
    supportTime:'支持全天候工作',
    searchBtn:'搜索',
    featured:'特色产品',
    latest:'最新产品',
    top:'评价最高的产品',
    review:'评价最多的产品',
    address:'地址',
    phone:'联系方式',
    email:'邮箱',
    useful:'合作伙伴',
    join:'立即加入',
    getEmail:'得到关于我们最新的商店和特别优惠的电子邮件更新。',
    subscribe:'订阅',
    enterEmail:'输入你的邮箱',
    saleOff:'折扣',
    price:'价格',
    sortBy:'排序',
    asc:'升序',
    desc:'降序',
    productsFound:'商品',
    detail:'详情',
    availability:'库存',
    shipping:'配送',
    shippingInfo:'48分直达',
    weight:'重量',
    description:'商品详情',
    related:'相关商品'
  }