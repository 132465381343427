import request from '@/utils/request'

// 导航栏
export function getNavList() {
    return request({
        url: '/hbgwOnstage/nav/invest',
        method: 'get'
    })
}
// 公司公告
export function getNotice(id,data) {
    return request({
        url: '/hbgwOnstage/nav/invest/'+id,
        method: 'get',
        params:data
    })
}
// 投资者服务[地图页面]
export function getInvestServer(id) {
    return request({
        url: '/hbgwOnstage/nav/invest/server/'+id,
        method: 'get'
    })
}
