import request from '@/utils/request'

export function getAboutUs() {
    return request({
        url: '/hbgwOnstage/nav/aboutUs',
        method: 'get'
    })
}
export function getAboutUsDetail(id,params) {
    return request({
        url: '/hbgwOnstage/nav/aboutUs/'+id,
        method: 'get',
        params:params
    })
}