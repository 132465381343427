<template>
    <div class="pagebanner">
        <img :src="urlObject[type]" />
    </div>
</template>
<script>
export default {
    props: {
        type: {
            type: Number,
            default: 0
        },
    },
    data() {
        return {
            urlObject:{
                0:require('../assets/images/153608_3_4.jpg'),
                1:require('../assets/images/153824_3_9.jpg'),
                2:require('../assets/images/153434_3_2.jpg'),
                3:require('../assets/images/153551_3_3.jpg'),
                4:require('../assets/images/153847_3_10.jpg'),
            }
        }
    }
}
</script>
<style scoped>
.pagebanner img {
    width: 100%;
    max-height: 300px;
}

@media(min-width:1000px) {
    .pagebanner img {
        min-height: 130px;
    }
}

@media(max-width:1000px) {
    .pagebanner img {
        min-height: 60px;
    }
}
</style>