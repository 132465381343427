<template>
  <div class="hgbw-page-area">
    <Header></Header>
    <div class="middle-area">
      <pageBanner :type="0"></pageBanner>
      <pageNavLine :leftNavShow="false" :infoList="navInfoList"></pageNavLine>
      <div class="newscenter-detail commonweb padtb">
        <div class="news-detail-title">
          <span class="date font22">{{ info.publishTime }}</span>
          <h2 class="font24">{{ info.title }}</h2>
          <pageShare :title="info.title" :pic="$BaseUrl + info.productImage"></pageShare>
        </div>

        <div class="padding-top-30 padding-bottim-30">
          <div class="text-center">
            <img alt="images" :src="$BaseUrl + info.image" />
          </div>
        </div>
        <div class="news-datail-content padtb">
          <div class="html-area ql-editor" v-html="$BaseUrl + info.content"></div>
        </div>
        <!-- <div class="news-detail-foot">
          <ul class="content-exp">
            <li class="text-overflow-ellipsis">上一篇： <a href="1375.cshtml.html"> 恒温恒湿精密空调过滤网的性能优势</a></li>
            <li class="text-overflow-ellipsis">下一篇： <a href="1373.cshtml.html">粉末冶金密度计厂家概述粉末冶金理论密度</a></li>
          </ul>
        </div> -->
      </div>
    </div>
    <Footer></Footer>
    <backToTop></backToTop>
  </div>
</template>
<script>
import { getNewsDetail } from "@/api/new.js";
import pageShare from "@/components/pageShare"
export default {
  components: { pageShare },
  data() {
    return {
      info: {},
      navInfoList: [{
        name: '新闻中心',
        url: '/news'
      }, {
        name: '新闻详情'
      }]
    }
  },
  mounted() {
    this.getDetail(this.$route.query.id)
  },
  methods: {
    getDetail(id) {
      getNewsDetail(id).then(res => {
        this.info = res
      })
    }
  }
}
</script>
<style scoped>
.html-area {
  margin: 0 auto;
  white-space: pre-wrap;
}
</style>