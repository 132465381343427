<template>
  <div>
    <!--PC端置顶-->
    <a class="zdsbacktop"></a>
    <!-- 移动端底部固定导航 -->
  <div class="footer-fixed">
    <ul>
        <li class="col-sm-4 col-xs-4">
            <a href="index.html">
                <i class="fa fa-home"></i>
                <span>首页</span>
            </a>
        </li>
        <li class="col-sm-4 col-xs-4">
            <a href="tel:0571-86953351">
                <i class="fa fa-phone-square"></i>
                <span>资讯我们</span>
            </a>
        </li>
        <li class="col-sm-4 col-xs-4">
            <a href="index.html" id="goToTops">
                <i class="fa fa-caret-square-o-up"></i>
                <span>置顶</span>
            </a>
        </li>
    </ul>
  </div>
</div>
</template>
<script>
export default {
  mounted() {
    // window.addEventListener('scroll', this.scrollFunction)
    setTimeout(() => {
      this.$nextTick(() => {
        let $goToTops = $('#goToTops');
        $goToTops.click(function () {
          $('html ,body').animate({ scrollTop: 0 }, 300);
          return false;
        });
      })
    }, 200)
  },
  destroyed() {
    // window.removeEventListener('scroll', this.scrollFunction)
  },
  methods: {
    // scrollFunction() {
    //   if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
    //     document.getElementById("movetop").style.display = "block";
    //   } else {
    //     document.getElementById("movetop").style.display = "none";
    //   }
    // },
    // topFunction() {
    //   document.body.scrollTop = 0;
    //   document.documentElement.scrollTop = 0;
    // }
  }
}
</script>
<style scoped>
.zdsbacktop{
  background-image: url('../assets/images/wicon89.png');
}
</style>